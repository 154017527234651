import React, { useState } from 'react'
import CheckAuthoristion from "../CheckAuthoristion";
import Header from "../Header";
import { MainURL } from '../../../API';

export default function IfreamMain() {
  const [presetId, setPresetId] = useState("");


  window.addEventListener('message', function (event) {
    try {
        // Parse the JSON string
        var messageData = JSON.parse(event.data);

        // Check the event type and handle accordingly
        if (messageData.event === 'ajaxSuccess') {
            // console.log('Ajax Success event received with ID:', messageData);
            // Perform actions based on the received ID
            setPresetId(messageData.id)

        }
    } catch (e) {
        console.error('Error parsing message data', e);
    }
});
  return (
    <div>
      <CheckAuthoristion />
      <div className="background">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-large" uk-grid="">
              {/* <Sidebar dashboard="currentli listcss overview" /> */}

              <div className="uk-width-expand@m content_div">
                <Header dashboard="uk-active" />
                {/* <div className="content"> */}
                <div className="contentdiv">
                  {/* <h3 className="hd">Custom Signals</h3> */}
                  <div>
                    <iframe
                      style={{ "width": "100%", "height": "100vh" }}  key={1}
                      // allowFullScreen="true" webkitallowfullscreen="true"
                      src={`${MainURL}api/builder/builder_form/?userid=${localStorage.getItem("builder_id")}&presetId=${presetId}`} ></iframe></div>

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
