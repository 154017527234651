import React from 'react'
// import './Nav.css'
import { Outlet, Link, useNavigate } from "react-router-dom";
import {
  ImageURL, MainURL
} from "../../API";
export default function Nav(props) {
  return (
    <div><div className="background">


      <div
        uk-sticky="uk-navbar-sticky"
        className="header_height uk-sticky uk-sticky-fixed menu"
        style={{}}
      >
        <nav
          className="uk-visible@m uk-background-muted uk-dark uk-box-shadow-small uk-sticky uk-navbar"
          uk-navbar=""
          style={{ height: 68 }}
        >
          <div className="uk-navbar-left">
            <a href="">
              <img src={MainURL + 'back-end/storage/app/public/img/logo/logo.png?rand=23'} className="logo" />{" "}
            </a>
          </div>
          <div className="uk-navbar-center">
            <ul className="uk-navbar-nav menuli">
              <li className={props.dashboard}>
                {/* <Link href="/admin">
              <i className="fa fa-home sidebar_iconw" /> Dashboard
            </Link> */}
                <Link to="/admin">
                  <i className="fa fa-home sidebar_iconw" /> Admin Form
                </Link>
              </li>
              <li className={props.adon}>
                {/* <Link href="/admin">
              <i className="fa fa-home sidebar_iconw" /> Dashboard
            </Link> */}
                <Link to="/admin/add_on">
                  <i className="fa fa-home sidebar_iconw" /> Add-ons Form
                </Link>
              </li>

              {localStorage.getItem('admin_type') === '0' &&
                <li className={props.staffs}>




                  <Link to="/admin/staff/view">
                    <i className="fa fa-user-plus sidebar_iconw" /> Staff
                  </Link>
                </li>}

              <li className={props.builders}>
                <Link to="/admin/builder/view">
                  <i className="fa fa-user-secret sidebar_iconw" /> Builders
                </Link>
              </li>

              <li className={props.quarantine}>
                <Link to="/admin/quarantine">
                  {/* <i className="fa fa-user-secret sidebar_iconw" /> */}
                  Quarantine
                </Link>
              </li>

              <li className={props.product}>
                <Link to="/admin/product">
                  <i className="fa fa-server sidebar_iconw" /> Products
                </Link>


              </li>
              {/* <li className="">
             <Link to="/admin/product">
             <i className="fa fa-line-chart sidebar_iconw" /> Quarantine
          </Link>
          </li> */}
              <li className={props.clients}>
                <Link to="/admin/client/view">
                  <i className="fa fa-users sidebar_iconw" /> Clients
                </Link>
              </li>
              {localStorage.getItem('admin_type') === '0' &&
                <li className={props.setting}>
                  <Link to="/admin/site/settings">
                    <i className="fa fa-cog sidebar_iconw" /> Settings
                  </Link>
                </li>}

              {/* {localStorage.getItem('admin_type') === '0' && (
                <li className={props.herf}>
                  <a href={`${MainURL}api/admin/settings/?userid=${localStorage.getItem("admin_id")}`}>
                    <i className="fa fa-superpowers  sidebar_iconw" /> Ahrefs
                  </a>
                </li>
              )} */}



            </ul>
          </div>
          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav uk-margin-large-left">
              <li>
                {" "}
                <a href="#">
                  <img src={ImageURL + localStorage.getItem("admin_img")} className="sampleimg" /> {localStorage.getItem("admin_fname")}{" "}
                  <span uk-icon="icon:  triangle-down" className="uk-icon" />
                </a>
                <div
                  uk-dropdown="mode: click;pos: top-left"
                  className="uk-dropdown dotdropw "
                >
                  <ul className="uk-nav uk-dropdown-nav dotdrop">
                    <li className="uk-active">
                      <Link to="/admin/profile">
                        <i className="fa fa-user-circle sidebar_iconw" /> My Profile
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/admin/logout">
                        <i className="fa fa-sign-out" /> Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    </div>

  )
}
Nav.defaultProps = {
  dashboard: "",
  adon: "",

  staffs: "",
  builders: "",
  clients: "",
  profile: "",
  product: "",
  quarantine: "",
  setting: "",
  herf: ""


};