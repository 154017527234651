import logo from './logo.svg';
// import './App.css';
import Home from "./components/admin/dashboard/Main";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminLogin from './components/admin/login/Login';
import AdminRegistration from './components/admin/Registration';
import Das from './components/Das';
import AddStaffs from './components/admin/staffs/AddStaffs';
import ViewStaffs from './components/admin/staffs/ViewStaffs';
import AdminLogout from './components/admin/login/Logout';
import ViewBuilders from './components/admin/builders/ViewBuilder';
import AddBuilders from './components/admin/builders/AddBuiders';
import ViewClients from './components/admin/clients/ViewClients';
import AddClients from './components/admin/clients/AddClients';
import UpdateClients from './components/admin/clients/UpdateClients';

import AdminPorfile from './components/admin/Porfile';
import UpdateStaffs from './components/admin/staffs/UpdateStaffs';
import UpdateBuiders from './components/admin/builders/UpdateBuiders';
import ViewProduct from './components/admin/products/ViewProduct';
import AddProduct from './components/admin/products/AddProduct';
import ProductUpdate from './components/admin/products/ProductUpdate';
import Setting from './components/admin/site/Setting';
import ForgetPassword from './components/admin/login/ForgetPassword';
import Nav from './components/admin/Nav';
import AddonMain from './components/admin/addon/Main';




import BuilderLogin from './components/builder/login/Login';
import BuilderLogout from './components/builder/login/Logout';
import BuilderForgetPassword from './components/builder/login/ForgetPassword';

import BuilderHome from "./components/builder/home/Main";
import BuilderHomeIfream from "./components/builder/home/IfreamMain";
import BuilderProject from "./components/builder/project/Project";


import BuilderProfile from "./components/builder/Porfile";
import ViewQuarantine from './components/admin/quarantine/ViewQuarantine';
import Ifream from './components/admin/products/Ifream';
import NotFound from './components/NotFound';
import Batch from './components/admin/batch/Batch';
import SettingIfream from './components/admin/site/SettingIfream';



function App() {
  return (
    // <Home /> 
    <BrowserRouter>
    <div>
      <Routes >
       

      <Route path="/admin" element={<Home />}></Route>

      <Route path="/admin/login" element={<AdminLogin />}></Route>
      <Route path="/admin/logout" element={<AdminLogout />}></Route>
      <Route path="/admin/forget/password" element={<ForgetPassword />}></Route>

      {/* <Route path="/admin/registration " element={<AdminRegistration />}></Route> */}

      <Route path="/admin/registration" element={<AdminRegistration />}></Route>
      {/* <Route path="/admin/registration" element={<AdminRegistration />}></Route> */}
      <Route path="/admin/2" element={<Das />}></Route>

      {localStorage.getItem('admin_type') === '0' && 

      <>
      
      <Route path="/admin/staff/view" element={<ViewStaffs />}></Route>

      <Route path="/admin/staff/add" element={<AddStaffs />}></Route>
      <Route path="/admin/staff/update" element={<UpdateStaffs />}></Route>
      </>
      }

      


      <Route path="/admin/builder/view" element={<ViewBuilders />}></Route>
      <Route path="/admin/builder/add" element={<AddBuilders />}></Route>
      <Route path="/admin/builder/update" element={<UpdateBuiders />}></Route>


      <Route path="/admin/client/view" element={<ViewClients />}></Route>
      <Route path="/admin/client/add" element={<AddClients />}></Route>
      <Route path="/admin/client/update" element={<UpdateClients />}></Route>


      <Route path="/admin/product" element={<ViewProduct />}></Route>
      <Route path="/admin/product/add" element={<AddProduct />}></Route>
      <Route path="/admin/product/update" element={<ProductUpdate />}></Route>
      <Route path="/admin/product/info" element={<Ifream />}></Route>





      <Route path="/admin/profile" element={<AdminPorfile />}></Route>

      {localStorage.getItem('admin_type') === '0' && <Route path="/admin/site/settings" element={<Setting />}></Route>}
      {localStorage.getItem('admin_type') === '0' && <Route path="/admin/batch" element={<Batch />}></Route>}
      {/* {localStorage.getItem('admin_type') === '0' && <Route path="/admin/ahrefs" element={<SettingIfream />}></Route>} */}
      

      <Route path="/admin/nav" element={<Nav />}></Route>



      <Route path="/admin/add_on" element={<AddonMain />}></Route>




      {/* <Route path="/admin/site/settings" element={<Setting />}></Route> */}

      <Route path="/admin/quarantine" element={<ViewQuarantine/>}></Route>

      <Route path="/builder/login" element={<BuilderLogin />}></Route>
      <Route path="/builder/logout" element={<BuilderLogout />}></Route>
      <Route path="/builder/forget/password" element={<BuilderForgetPassword/>}></Route>

      {/* <Route path="/builder/home" element={<BuilderHome/>}></Route> */}
      <Route path="/builder/home" element={<BuilderHomeIfream/>}></Route>
      <Route path="/builder" element={<BuilderHomeIfream/>}></Route>
      <Route path="/builder/project" element={<BuilderProject/>}></Route>


      <Route path="/builder/profile" element={<BuilderProfile/>}></Route>
      <Route path="*" element={<NotFound/>}></Route>

      
      </Routes>
      </div>
      </BrowserRouter>
  );
}

export default App;
