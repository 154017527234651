import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  const navigate = useNavigate();
  const logout = async () => {
    localStorage.clear();
    if (!localStorage.getItem("builder_token")) {
      navigate("/builder/login");
    }
  };
  useEffect(() => {
    logout();
  });
  return <div></div>;
}
