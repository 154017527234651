import React from "react";
import CheckAuthoristion from "../CheckAuthoristion";
import Sidebar from "../Sidebar";
import Header from "../Header";
// import Nev from '../Nev'

import UplodeCSV from "./UplodeCSV";
import ShowInfo from "./ShowInfo";
import AddCountry from "./AddCountry";
import AddNiche from "./AddNiche";
import AddSignalsTypes from "./AddSignalsTypes";
import ShowDuplicates from "./ShowDuplicates";
import AddAddon from "./AddAddon";
import AllData from "./AllDate";
import AddAddonUplodeCSV from "./AddonUplodeCSV";
import AddDofollow from "./AddDofollow";
import AddProduct from "./AddProduct";
import Tablecontante from "./Tablecontante";
import Ifream from "./Ifream";

import AddNotes from "./AddNotes";
import Addsub_niche from "./AddSubNiche";
import Addcity from "./AddCity";
import Addstate from "./AddState";
import { ToastContainer } from "react-toastify";
import AddOutreach from "./AddOutreach";

export default function Main() {
  return (
    <div>
      <CheckAuthoristion />
      <div className="background">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-large" uk-grid="">
              {/* <Sidebar dashboard="currentli listcss overview" /> */}

              <div className="uk-width-expand@m content_div">
                <Header dashboard="uk-active" />
                <div className="content">
                  <div className="contentdiv">
                    <h3 className="hd">Custom Signals</h3>
                    {/* <ShowDuplicates /> */}
                    {/* <ShowInfo /> */}
                    {/* <AllData/> */}
                    <Ifream />
                    <Tablecontante /> <br />
                    <hr />
                    <div className="uk-grid-small" uk-grid="">
                      

                      <div className="uk-width-1-3@s">
                        <AddCountry />
                      </div>
                      <div className="uk-width-1-3@s">
                        <Addstate />
                      </div>
                      <div className="uk-width-1-3@s">
                        <Addcity />
                      </div>
                      <div className="uk-width-1-3@s">
                        <AddProduct />
                      </div>
                      <div className="uk-width-1-3@s">
                        <AddNiche />
                      </div>

                      <div className="uk-width-1-3@s">
                        <AddSignalsTypes />
                      </div>

                      <div className="uk-width-1-3@s">
                        <AddNotes />
                      </div>
                      <div className="uk-width-1-3@s">
                        <Addsub_niche />
                      </div>
                      <div className="uk-width-1-3@s">
                        <AddOutreach />
                      </div>
                    </div>
                    <UplodeCSV />
                    {/* <AddAddon/>
                <AddAddonUplodeCSV/>
                <AddDofollow/> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

    </div>
  );
}
