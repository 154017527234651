import React, { useState } from "react";
import CheckAuthoristion from "../CheckAuthoristion";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { Outlet, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { UpdateSiteLogoAPI, key, DeleteUsersAPI, MainURL } from "../../../API";
import { ToastContainer, toast } from "react-toastify";
import ClearAll  from "./ClearAll";
import Backup  from "./Backup";
import  ResetBackup from "./ResetBackup";




export default function AddStaffs() {
  const [file, setFile] = useState("");

  function hanleFile(e) {
    // //console.log(e.target.files);
    setFile(e.target.files[0]);
    //console.log(file);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append("img", file);
    formData.append("key", key);
    formData.append("admin_token", localStorage.getItem("admin_token"));
    formData.append("id", localStorage.getItem("updatation_id_for_builder"));

    // //console.log("ok img ");

    axios.post(UpdateSiteLogoAPI, formData).then(
      (res) => {
        if (res.data.status_code === 200) {
          // successMessage(res.data.message);
          showToastMessage(res.data.message);
          // setUserPhotoFile(res.data.data[0].img)
          // //console.log(res.data.data[0].img);
        } else {
          showToastMessageError(res.data.message);
        }
      },
      (reason) => {
        console.error(reason); // Error!
        showToastMessageError("something wrong please try again");
      },
    );
  };
  const showToastMessage = (text) => {
    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastMessageError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  return (
    <div>
      <CheckAuthoristion />
      <div className="background">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-large" uk-grid="">
              {/* <Sidebar setting="currentli listcss" /> */}

              <div className="uk-width-expand@m content_div">
                <Header setting="uk-active" />

                <div className="content">
                  <div className="contentdiv">
                    <div className="" uk-grid="">
                      <div className="uk-width-expand@m">
                        <div className="" uk-grid="">
                          <div className="uk-width-auto">
                            <h3 className="hd"> Site Setting</h3>
                          </div>
                          {/* <div className="uk-width-expand">
                            <div className="uk-width-1-1@s uk-text-right uk-margin-small-bottom">
                              <Link
                                to="/admin/staff/view"
                                className="uk-button uk-button-danger custombtn"
                              >
                                <i className="fa fa-arrow-left" /> Go Back{" "}
                              </Link>
                            </div>
                          </div> */}
                        </div>
                        <br />




                      <div className="uk-card uk-card-body uk-card-default addcandidate">
                           
                        <div><iframe
                            style={{ "width": "100%", "height": "750px" }} key={key}
                            src={`${MainURL}api/admin/settings/?userid=${localStorage.getItem("admin_id")}`} ></iframe></div>

                        </div>
                        <br /> 







                        <div className="uk-card uk-card-body uk-card-default addcandidate">
                          <h3 className="ukshd">
                            {/* <u> Basic Informations</u> */}
                          </h3>
                          <form
                            method="post"
                            onSubmit={onSubmit}
                            autoComplete="off"
                            encType="multipart/form-data"
                          >
                            <div className="uk-grid-small" uk-grid="">
                              {/* <div className="uk-width-1-4@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {" "}
                    Phone Number <span className="uk-text-danger">*</span>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="phone"
                      type="number"
                      placeholder="Enter Phone Number"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div> */}
                              {/* <div className="uk-width-1-4@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {" "}
                    Date of birth <span className="uk-text-danger">*</span>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="dob"
                      type="date"
                      placeholder="Enter Date of birth"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div> */}

                              {/* <div class="uk-width-1-4@s">
              <div class="uk-margin">
                 <label class="uk-form-label"> Designation <span class="uk-text-danger">*</span> </label>
                  <div class="uk-form-controls">
                      <input class="uk-input" name="designation" type="text" placeholder="Enter Designation" value="">
                  </div>
              </div>
      </div>*/}

                              {/*<div class="uk-width-1-4@s">
          <div class="uk-margin">
                 <label class="uk-form-label">Status </label>
                  <div class="uk-form-controls">
                     <select class="uk-select" name="status">
                                  <option value="0" >
                                      Active
                                  </option>
                                  <option value="1" >
                                      Inactive
                                  </option>
                              </select>
                  </div>
              </div>
      </div>*/}

                              <div className="uk-width-expand@s">
                                <div className="uk-margin ">
                                  <label className="uk-form-label">
                                    {" "}
                                    Image Upload{" "}
                                  </label>
                                  <div className="uk-form-controls uk-margin-small-top">
                                    <input
                                      onChange={hanleFile}
                                      name="resume_pic"
                                      type="file"
                                    />{" "}
                                    <img
                                      src={MainURL+'back-end/storage/app/public/img/logo/logo.png?rand=23'}
                                      className="logo"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />

                            <div className="uk-width-1-1@s uk-margin-small-top">
                              <button
                                type="submit"
                                className="uk-button  uk-button-primary custombtn"
                                name="submit_candidate"
                                style={{ "backgroundColor": "#4CAF50" }}
                              >
                                <i className="fa fa-save"></i> Save Logo
                              </button>
                            </div>
                          </form>
                        </div>
                        <br />

                        {/* <ClearAll/><br /> */}
                        <Backup/><br />
                        {/* <ResetBackup/> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
