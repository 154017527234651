import React from 'react'
// import './Nav.css'
import { Outlet, Link, useNavigate} from "react-router-dom";
import {
  ImageURL, MainURL
} from "../../API";
export default function Nav(props) {
  return (
  <div><div className="background">
   
   
  <div
    uk-sticky="uk-navbar-sticky"
    className="header_height uk-sticky uk-sticky-fixed menu"
    style={{}}
  >
    <nav
      className="uk-visible@m uk-background-muted uk-dark uk-box-shadow-small uk-sticky uk-navbar"
      uk-navbar=""
      style={{ height: 68 }}
    >
      <div className="uk-navbar-left">
        <a href="">
          <img src={MainURL+'back-end/storage/app/public/img/logo/logo.png?rand=23'} className="logo" />{" "}
        </a>
      </div>
      <div className="uk-navbar-center">
        <ul className="uk-navbar-nav menuli">
          <li className={props.dashboard}>
            {/* <Link href="/admin">
              <i className="fa fa-home sidebar_iconw" /> Dashboard
            </Link> */}
            <Link to="/builder/home">
            <i className="fa fa-home sidebar_iconw" /> Builder Form
          </Link>
          </li>

          <li className={props.profile}>
            {/* <Link href="/admin">
              <i className="fa fa-home sidebar_iconw" /> Dashboard
            </Link> */}
            <Link to="/builder/profile">
            <i className="fa fa-user-circle sidebar_iconw" /> Profile
          </Link>
          </li>
          <li className={props.project}>
            <Link to="/builder/project">
            <i className="fa fa-cubes sidebar_iconw" /> Projects
          </Link>
          </li>
          <li >
            {/* <Link href="/admin">
              <i className="fa fa-home sidebar_iconw" /> Dashboard
            </Link> */}
           <Link to ="/builder/logout">
                    <i className="fa fa-sign-out" /> Logout
                  </Link>
          </li>
         
       
           
         
        </ul>
      </div>
      <div className="uk-navbar-right">
        <ul className="uk-navbar-nav uk-margin-large-left">
          <li>
            {" "}
            <a href="#">
              <img src={ImageURL + localStorage.getItem("builder_img")} className="sampleimg" /> {localStorage.getItem("builder_fname")}{" "}
              <span uk-icon="icon:  triangle-down" className="uk-icon" />
            </a>
            <div
              uk-dropdown="mode: click;pos: top-left"
              className="uk-dropdown dotdropw "
            >
              <ul className="uk-nav uk-dropdown-nav dotdrop">
                <li className="uk-active">
                <Link to="/builder/profile">
            <i className="fa fa-user-circle sidebar_iconw" /> Profile
          </Link>
                </li>
                <li className="">
                <Link to ="/builder/logout">
                    <i className="fa fa-sign-out" /> Logout
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
</div>

  )
}
Nav.defaultProps = {
  dashboard: "",
  adon: "",

  staffs: "",
  builders: "",
  clients:"",
  profile:"",
  product:"",
  setting:"",

  Project:""
};