import React, { useEffect, useState } from "react";
import CheckAuthoristion from "../CheckAuthoristion";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Outlet, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  GetAdminAPI,
  key,
  UpdateUsersAPI,
  UpdatePasswordUsersAPI,
  UpdateUserImgsAPI,
  ImageURL
} from "../../../API";
import { ToastContainer, toast } from "react-toastify";

export default function Porfile() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");
    const [address, setAddress] = useState("");
    const [file, setFile] = useState("");
    const [userPhotoFile, setUserPhotoFile] = useState("");


    function hanleFile(e) {
      // console.log(e.target.files);
      setFile(e.target.files[0]);
      console.log(file)
    }
    const inputEventFirstName = (event) =>{
        
      setFirstName(event.target.value)
  }
  const inputEventLastName = (event) =>{
        
    setLastName(event.target.value)
}

    const inputEventEmail = (event) =>{
        
      setEmail(event.target.value)
  }
  const inputEventPassword = (event) =>{
      
      setPassword(event.target.value)
  }
  const inputEventCPassword = (event) =>{
      
    setCPassword(event.target.value)
}
const inputEventAddress = (event) =>{
      
  setAddress(event.target.value)
}
const onSubmitUpdatePassword = async (event)=>{
  event.preventDefault()
  const formData = new FormData();

    formData.append("img", file);
    formData.append("key", key);
    formData.append("admin_token", localStorage.getItem("admin_token"));
    formData.append("id", localStorage.getItem("updatation_id_for_staff"));


    formData.append("password", password);
    formData.append("cpassword", cPassword);
 
     

    console.log("okfa")



    
  
    axios.post(UpdatePasswordUsersAPI, formData).then((res) => {
      if (res.data.status_code === 200) {
        // successMessage(res.data.message);
      showToastMessage(res.data.message)

      }
      else{
      showToastMessageError(res.data.message)

      }
    },(reason) => {
      console.error(reason); // Error!
      showToastMessageError("something wrong please try again")

    },);
  
}

const onSubmitUpdateImg = async (event)=>{
  event.preventDefault()
  const formData = new FormData();

    formData.append("img", file);
    formData.append("key", key);
    formData.append("admin_token", localStorage.getItem("admin_token"));
    formData.append("id", localStorage.getItem("updatation_id_for_staff"));


  

   
      

    axios.post(UpdateUserImgsAPI, formData).then((res) => {
      if (res.data.status_code === 200) {
        // successMessage(res.data.message);
      showToastMessage(res.data.message)
      setUserPhotoFile(res.data.data[0].img)
      console.log(res.data.data[0].img)

      }
      else{
      showToastMessageError(res.data.message)

      }
    },(reason) => {
      console.error(reason); // Error!
      showToastMessageError("something wrong please try again")

    },);
  
}

const onSubmit = async (event)=>{
  event.preventDefault()
  if(firstName.replace(" ", "") == ""){
    showToastMessageError("please enter valid name")

  }
  else{
  const formData = new FormData();

 
    formData.append("key", key);
    formData.append("admin_token", localStorage.getItem("admin_token"));
    formData.append("id", localStorage.getItem("updatation_id_for_staff"));


    formData.append("firstname", firstName);
    formData.append("lastname", lastName);
    formData.append("email", email);
    formData.append("address", address);
     

    console.log("okfa")



    
  
    axios.post(UpdateUsersAPI, formData).then((res) => {
      if (res.data.status_code === 200) {
        // successMessage(res.data.message);
      showToastMessage(res.data.message)

      }
      else{
      showToastMessageError(res.data.message)

      }
    },(reason) => {
      console.error(reason); // Error!
      showToastMessageError("something wrong please try again")

    },);
  }
  
}

const showToastMessage = (text) => {
  toast.success(text, {
      position: toast.POSITION.TOP_RIGHT
  });

   
};

const showToastMessageError = (text) => {
toast.error(text, {
  position: toast.POSITION.TOP_RIGHT
});
}

  const getAllDataAPI = async () => {
    try {
      const response = await axios.post(GetAdminAPI, {
        key: key,
         
        id: localStorage.getItem("updatation_id_for_staff"),
         
        admin_token:localStorage.getItem('admin_token')
        
      });
    console.log(response.data.data)
    console.log(response.data.data[0].firstname)

    setFirstName(response.data.data[0].firstname)
    if(response.data.data[0].lastname == null || response.data.data[0].lastname == "null"){
    setLastName("")

    }else{
      setLastName(response.data.data[0].lastname)

    }
    setEmail(response.data.data[0].email)
    setAddress(response.data.data[0].address)
    setUserPhotoFile(response.data.data[0].user_photo_file)




      
    //   setData(response.data.data);
    
    // setFilteredQuizss(response.data.data);
     
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getAllDataAPI();
  }, []);

  return (
    <div>
      <CheckAuthoristion />
      <div className="background">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-large" uk-grid="">
              {/* <Sidebar staffs="currentli listcss" /> */}

              <div className="uk-width-expand@m content_div">
             <Header staffs="uk-active"/>
                 
                <div className="content">
                  <div className="contentdiv">
                    <div className="" uk-grid="">
                      <div className="uk-width-expand@m">
                        <div className="" uk-grid="">
                          <div className="uk-width-auto">
                            <h3 className="ukhd"> Update Staff</h3>
                          </div>
                          <div className="uk-width-expand">
                            <div className="uk-width-1-1@s uk-text-right uk-margin-small-bottom">
                              <Link
                                to="/admin/staff/view"
                                className="uk-button uk-button-danger custombtn"
                              >
                                <i className="fa fa-arrow-left" /> Go Back{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="uk-grid-small" uk-grid="">
                          <div className="uk-width-3-5@s">
                            <div className="uk-card uk-card-body uk-card-default addcandidate">
                              <h3 className="ukshd">
                                {/* <u> Basic Informations</u> */}
                              </h3>
                              <form onSubmit={onSubmit} method="post" encType="multipart/form-data">
                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        {" "}
                                        First Name{" "}
                                        <span className="uk-text-danger">
                                          *
                                        </span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <input
                                          className="uk-input"
                                          name="fname"
                                          type="text"
                                          placeholder="Enter First Name"
                                       value={firstName}
                                          onChange={inputEventFirstName}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        {" "}
                                        Last Name{" "}
                                      </label>
                                      <div className="uk-form-controls">
                                        <input
                                          className="uk-input"
                                          name="lname"
                                          type="text"
                                          placeholder="Enter Last Name"
                                          value={lastName}
                                         onChange={inputEventLastName}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                         
                                  <div className="uk-width-2-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        Email{" "}
                                        <span className="uk-text-danger">
                                          *
                                        </span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <input
                                          className="uk-input"
                                          name="email"
                                          type="email"
                                          placeholder="Enter Email"
                                          readOnly
                                            value={email}
                                           onChange={inputEventEmail}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                  <div className="uk-width-2-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        Address{" "}
                                        <span className="uk-text-danger"></span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <textarea
                                          className="uk-textarea"
                                          name="address"
                                          placeholder="Enter Address"
                                            value={address}
                                           onChange={inputEventAddress}
                                          rows={3}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                   
                                    
                                </div>
                                <hr />

                                <div className="uk-width-1-1@s uk-margin-small-top">
                                <button
                                    type="submit"
                                    className="uk-button  uk-button-primary custombtn"
                                    name="submit_candidate"
                                    style={{"background-color": "#4CAF50"}}

                                  >
                                    <i class="fa fa-save"></i> Save Staff
                                     
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="uk-width-2-5@s">
                            <div className="uk-card uk-card-body uk-card-default addcandidate">
                              <h3 className="ukshd">
                                <u> Password Update</u>
                              </h3>
                              <form method="post" onSubmit={onSubmitUpdatePassword} encType="multipart/form-data">
                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-2-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        {" "}
                                        Password{" "}
                                        <span className="uk-text-danger">
                                          *
                                        </span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <input
                                          className="uk-input"
                                          name="password"
                                          type="password"
                                          placeholder="Enter Password"
                                            value={password}
                                            onChange={inputEventPassword}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div><br />
                                  <div className="uk-width-2-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        Confirm Password{" "}
                                        <span className="uk-text-danger">
                                          *
                                        </span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <input
                                          className="uk-input"
                                          name="confirmpassword"
                                          type="password"
                                          placeholder="Enter Confirm Password"
                                            value={cPassword}
                                            onChange={inputEventCPassword}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  
                                </div>
                                <hr />

                                <div className="uk-width-1-1@s uk-margin-small-top">
                                <button
                                    type="submit"
                                    className="uk-button  uk-button-primary custombtn"
                                    name="submit_candidate"
                                    style={{"background-color": "#4CAF50"}}

                                  >
                                    <i class="fa fa-save"></i> Update Password
                                     
                                  </button>                                </div>
                              </form>
                            </div>
                            <br />
                            <div className="uk-card uk-card-body uk-card-default addcandidate">
                               
                              <form method="post" onSubmit={onSubmitUpdateImg} encType="multipart/form-data">
                                <div className="uk-grid-small" uk-grid="">
                                   
                                 

                                  
                                  <div className="uk-width-expand@s  ">
                                    <div className="uk-margin ">
                                      <label className="uk-form-label">
                                        {" "}
                                        Image Update{" "}
                                      </label>
                                      <div className="uk-form-controls uk-margin-small-top">
                                        <input onChange={hanleFile} name="resume_pic" type="file" /><img src={ImageURL + userPhotoFile} width={25} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />

                                <div className="uk-width-1-1@s uk-margin-small-top">
                                <button
                                    type="submit"
                                    className="uk-button  uk-button-primary custombtn"
                                    name="submit_candidate"
                                    style={{"background-color": "#4CAF50"}}

                                  >
                                    <i class="fa fa-save"></i> Save Image
                                     
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
}
