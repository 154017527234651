import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify';
import CheckAuthoristion from '../CheckAuthoristion';
import Header from "../Header";
import { MainURL } from '../../../API';
import { useNavigate } from 'react-router-dom';

export default function Batch() {
    const navigate = useNavigate();

    const [Id, setId] = useState(0);

    useEffect(() => {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        var id = ''
        if (urlParams.has("id")) {
            id = urlParams.get("id");
            setId(id)
            // alert(id)
        } else {
            navigate("/admin/site/settings");

        }


    }, [Id]);
    return (
        <div>
            <CheckAuthoristion />
            <div className="background">
                <div className="container">
                    <div className="uk-width-1-1">
                        <div className="uk-grid-large" uk-grid="">
                            {/* <Sidebar setting="currentli listcss" /> */}

                            <div className="uk-width-expand@m content_div">
                                {/* <Header setting="uk-active" /> */}
                                <Header />

                                <div className="content">
                                    <div className="contentdiv">
                                        <div className="" uk-grid="">
                                            <div className="uk-width-expand@m">
                                                <div className="" uk-grid="">
                                                    <div className="uk-width-auto">
                                                        <h3 className="hd">Not Processed Ahrefs</h3>
                                                    </div>

                                                </div>



                                                <br />

                                                <div><iframe
                                                    style={{ "width": "100%", "height": "700px" }} key={0}
                                                    src={`${MainURL}api/admin/batch/?userid=${localStorage.getItem("admin_id")}&id=${Id}`} ></iframe></div>









                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
