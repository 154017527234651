import React from 'react'
import CheckAuthoristion from "../CheckAuthoristion";
import Header from "../Header";
import { MainURL } from '../../../API';

export default function IfreamMain() {
  return (
    <div>
      <CheckAuthoristion />
      <div className="background">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-large" uk-grid="">
              {/* <Sidebar dashboard="currentli listcss overview" /> */}

              <div className="uk-width-expand@m content_div">
                <Header project="uk-active" />
                {/* <div className="content"> */}
                <div className="contentdiv">
                  {/* <h3 className="hd">Custom Signals</h3> */}
                  <div>
                    <iframe
                      style={{ "width": "100%", "height": "750px" }}  key={1}
                      // allowFullScreen="true" webkitallowfullscreen="true"
                      src={`${MainURL}api/builder/projects/?userid=${localStorage.getItem("builder_id")}`} ></iframe></div>

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
