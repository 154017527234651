import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import {
     GetAllAdminAPI,
     key,
     DeleteUsersAPI,
     ImageURL
  } from "../../../API";
  import { ToastContainer, toast } from "react-toastify";
  import { Outlet, Link, useNavigate} from "react-router-dom";



 export default function StaffTable() {
  const navigate = useNavigate();

  const [getData, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [FilteredQuizs, setFilteredQuizss] = useState([]);



  const getAllDataAPI = async () => {
    try {
      const response = await axios.post(GetAllAdminAPI, {
        key: key,
        type: 1
        // id: localStorage.getItem("admin_id"),
      });
      //console.log(response.data.data)
      console.clear()
      
      setData(response.data.data);
    //   setSearch(response.data.data);
    setFilteredQuizss(response.data.data);
     
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    getAllDataAPI();
  }, []);
  const columns = [
    {
      name: "S.No",
      // selector: (row, index) => index + 1,
      selector: (row) => row.index,

      sortable: true,
      style: {
        // background: "orange",
        
      },
      headerStyle: (selector, id) => {
        return { width: "2px", textAlign: "center" };
      },
      maxWidth: "100px",
      minWidth: "30px"
    },
    {
      name: "Name",
      selector: (row) => {
        if (row.lastname == null || row.lastname == "null") {
          return row.firstname;
        } else {
          return row.firstname + " " + row.lastname;
        }
      },
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
       
    },
    // {
    //   name: "Address",
    //   selector: (row) => row.address,
    //   sortable: true,
    // },

    {
      name: "Image",
      selector: (row) => {
        return <img src={ImageURL + row.user_photo_file} width={25} />;
        // return <img src={"https://vineet.customlinkbuilders.com/back-end/storage/app/public/img/users/user.png"} width={30} />;

      },
      sortable: true,
    },
     
    
 

    {
      name: "Action",
      cell: (row) => (
        <div className=" ">
          <div
            className=""
            // onClick={() => updateAdmin(row.id)}
          >
            <button onClick={() => update(row.id)} class="uk-button uk-button-default uk-button-small"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></button>
            <button onClick={() => deleteRow(row.id)}  class="uk-button uk-button-default uk-button-small">            <i class="fa fa-trash fa-lg" aria-hidden="true"></i>
</button>

             
            
 
          </div>
          <div
            className=""
            // onClick={() => testfunction(row.id)}
            // onClick={() => alert(row.id)}
          >
            {/* <TrashIcon /> */}
           

          </div>
          {/* 
          <div
            className="h-5 w-5 cursor-pointer"
            // onClick={() => testfunction(row.id)}
            onClick={() => addQuizData(row.id)}
            // onClick={() => alert(row.id)}
          >
            <PlusIcon />

            
          </div> */}
        </div>
      ),
    },
  ];
  
  const showToastMessage = (text) => toast(text)
  useEffect(() => {
    const result = getData.filter((temp) => {
      // return student;
      let data =
      temp.firstname.toLowerCase().match(search.toLocaleLowerCase()) +
      temp.email.toLowerCase().match(search.toLocaleLowerCase());
      if (data == "") {
        data = temp.firstname.toLowerCase().match(search.toLocaleLowerCase()) 
      }

      return data;
    });
    setFilteredQuizss(result);
  }, [search]);

  const update = async (id) => {
    localStorage.setItem("updatation_id_for_staff", id);
    navigate("/admin/staff/update");
  };

  const deleteRow = async (id) => {
    let text = "Are you sure? You want to delete this?";
    if (window.confirm(text) === true) {
        
      try {
        const response = await axios.post(DeleteUsersAPI, {
          key: key,
          id: id,
          admin_token:localStorage.getItem('admin_token')
        });
         
        if (response.status === 200) {
          getAllDataAPI();
          showToastMessage(response.data.message)
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
   return (
     <div>
      {getData.length == 0 ? (
        <div>
          <center>
          <span uk-spinner="ratio: 4.5"></span>
        <h1>  Please wait... </h1>

          </center>
        
        </div>
        
        
      ) : <DataTable
    //  title = "Staff"
     columns={columns}
     data={FilteredQuizs}
     pagination
     fixedHeader
     fixedHeaderScrollHeight="330px"
     selectableRowsHighlight
     highlightOnHover
     subHeader
     subHeaderComponent={
      
           <input
             id="desktop-search"
             className="uk-align-right mx-2"
             placeholder="Search"
             type="search"
             name="search"
             value={search}
             onChange={(e) => setSearch(e.target.value)}
           />
         
     }
    //  subHeaderAlign="left"
   />
 }<ToastContainer /></div>
   )
 }
 