import React, { useEffect, useState } from 'react'
import { BaseURL, key } from '../../../API';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import LoadingModal from '../../loader/LoadingModal';

export default function ResetBackup() {

    const [loadder, setLoadder] = useState(false);
    const [data, setData] = useState([]);


    const onSubmit = async (value) => {
        let text = "Are you sure? You want to restore backup?";
        if (window.confirm(text) === true) {
            setLoadder(true);
            try {
                const response = await axios.post(BaseURL + "/backup/restore", {
                    key: key,
                    admin_token: localStorage.getItem("admin_token"),
                    date_time: value
                });

                if (response.status === 200) {
                    showToastMessage(response.data.message);
                }
                setLoadder(false);
            } catch (error) {
                showToastMessageError("sorry! sever error");
                setLoadder(false);
            }
        }
    };
    const showToastMessage = (text) => {
        toast.success(text, {
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    const showToastMessageError = (text) => {
        toast.error(text, {
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    const getAllDataAPI = async () => {
        try {
            const response = await axios.post(BaseURL + `/backup/allget`, {
                key: key,
                admin_token: localStorage.getItem("admin_token"),
            });
            // console.log(response.data.data);


            setData(response.data.data);

        } catch (error) {
            // console.log(error);


        }
    };

    useEffect(() => {
        getAllDataAPI();
    }, []);
    return (
        <div className="uk-card uk-card-body uk-card-default addcandidate">
            {/* {loadder ? (
                <>
                    <div>
                        <span uk-spinner="ratio: 2.3"></span>
                        <h3>
                            {" "}
                            Please wait... <br />
                            Restoring Backup
                        </h3>
                    </div>
                </>
            ) : (
                
            )} */}
            <>
                

                    <table class="uk-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th>Backup's</th>
                                <th>Action</th>
                            </tr>
                             
                        </thead>
                        
                        {data.map((i) => {
                        return <tbody>
                        <tr>
                            <td>{i}</td>
                            <button
                        onClick={()=>{onSubmit(i)}}
                        className="uk-button uk-button-primary"
                        type="button"
                    >
                        Restore Backup
                    </button>
                        </tr>
                    </tbody>;
                      })}
                       
                    </table>
                </>
          <LoadingModal isLoading={loadder} />

        </div>
    )
}
