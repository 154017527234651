import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import { key, BaseURL } from "../../../API";

import CheckAuthoristion from "../CheckAuthoristion";
import Table from "./Table";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export default function ViewQuarantine() {
  const [getLoadding, setLoadding] = useState(false);

  const update_btn = async () => {
    let text = "Are you sure? You want to change this?";
    if (window.confirm(text) === true) {
      document.getElementById("update_btn").disabled = true;
      setLoadding(true);

      try {
        const response = await axios.post(BaseURL + `/update/all/quarantine`, {
          key: key,
          admin_token: localStorage.getItem("admin_token"),
        });

        if (response.status === 200) {
          // console.log(response.data);

        }
        showToastMessage(response.data.message);

        document.getElementById("update_btn").disabled = false;
        setLoadding(false);
      } catch (error) {
        document.getElementById("update_btn").disabled = false;
        showToastMessage("sorry! sever error");

        console.log(error);
        setLoadding(false);
      }
    }
  };
  const showToastMessage = (text) => toast(text);

  return (
    <div>
      <CheckAuthoristion />
      <div className="background">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-large" uk-grid="">
              {/* <Sidebar product ="currentli listcss" /> */}

              <div className="uk-width-expand@m content_div">
                <Header quarantine="uk-active" />
                <div className="contentdiv">
                  <div className="" uk-grid="">
                    <div className="uk-width-expand@m">
                      <div className="" uk-grid="">
                        <div className="uk-width-auto">
                          <h3 className="hd"> Quarantine</h3>
                        </div>
                        <div className="uk-width-expand">
                          <div className="uk-width-1-1@s uk-text-right uk-margin-small-bottom">
                            {/* <button
                              to="/admin/product/add"
                              className="uk-button uk-button-primary custombtn"
                              onClick={update_btn}
                              id="update_btn"
                            >
                              <i className="fa fa-plus-square" /> Check URL's{" "}
                              <i class="fa-regular fa-arrow-left"></i>
                            </button> */}
                          </div>
                        </div>
                      </div>
                      <hr />
                      {getLoadding ? (
                        <>
                          <div>
                            <center>
                              <span uk-spinner="ratio: 4.5"></span>
                              <h1> Please wait... </h1>
                            </center>
                          </div>
                        </>
                      ) : (
                        <Table />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

    </div>
  );
}
