import React from 'react'

export default function Tablecontante() {
  return (
    <div className="" uk-grid="">
    <div className="uk-width-expand@m">
      
      <div className="uk-card uk-card-body uk-card-default ">
        <h5>
        "Target URL" VALUE - domain.com Format
<br />
"Country" VALUE - Multiple values can be chosen
<br />
"State" VALUE - State or GLOBAL
<br />
"City" VALUE - Multiple values can be chosen or GLOBAL
<br />
"Niche" VALUE - Multiple values can be chosen or GLOBAL
<br />
"Type" VALUE - Multiple values can be chosen
<br />
"DR" VALUE - Numerical value
<br />
"Average Traffic" VALUE - Numerical value
<br />
"Submission Page" VALUE - URL Format
        </h5>
         
      </div>
    </div>
    {/* <ToastContainer /> */}
  </div>
  )
}
