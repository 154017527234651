import React from "react";
import { ToastContainer, toast } from "react-toastify";

import CheckAuthoristion from "../CheckAuthoristion";
import Sidebar from "../Sidebar";
import Header from "../Header";
// import Nev from '../Nev'

import  ShowDuplicates from './ShowDuplicates'
import AddAddon from "./AddAddon";
// import  AllData from './AllDate'
import AddAddonUplodeCSV from "./AddonUplodeCSV";
import AddDofollow from "./AddDofollow";
// import  AddProduct from './AddProduct'


import AddNotes from "./AddNotes";
import Addnotes from "./AddNotes";
import Tablecontante from './Tablecontante'
import Ifream from './Ifream'
export default function Main() {
  return (
    <div>
      <CheckAuthoristion />
      <div className="background">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-large" uk-grid="">
              {/* <Sidebar dashboard="currentli listcss overview" /> */}

              <div className="uk-width-expand@m content_div">
                <Header adon="uk-active" />
                <div className="content">
                  <div className="contentdiv">
                    <h3 className="hd">Add-ons Update Form
</h3>
{/* <ShowDuplicates /> */}
<Ifream/><Tablecontante/> <br /><hr />

                    <div className="uk-grid-small" uk-grid="">
                      <div className="uk-width-1-3@s">
                        <AddAddon />
                      </div>

                      <div className="uk-width-1-3@s">
                        <AddDofollow />
                      </div>
                      <div className="uk-width-1-3@s">
                        <Addnotes />
                      </div>
                    </div>
                    <AddAddonUplodeCSV />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
}
