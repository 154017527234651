import React from 'react'
import CheckAuthoristion from '../CheckAuthoristion'
import Sidebar from '../Sidebar'
import Header from '../Header'
import StaffTable from './StaffTable'

import { Outlet, Link, useNavigate} from "react-router-dom";
export default function ViewStaffs() {
  return (
    <div><CheckAuthoristion/>
    <div className="background">
    
    
    <div className="container">
      <div className="uk-width-1-1">
         
        <div className="uk-grid-large" uk-grid="">
        {/* <Sidebar staffs="currentli listcss" /> */}

          <div className="uk-width-expand@m content_div">
          <Header staffs="uk-active"/>

             <div className="contentdiv">
    <div className="" uk-grid="">
      <div className="uk-width-expand@m">
        <div className="" uk-grid="">
          <div className="uk-width-auto">
            <h3 className="hd"> Staffs</h3>
          </div>
          <div className="uk-width-expand">
            <div className="uk-width-1-1@s uk-text-right uk-margin-small-bottom">
              <Link
                to="/admin/staff/add"
                className="uk-button uk-button-primary custombtn"
              >
                <i className="fa fa-plus-square" /> {" "} Add Staff{" "}
                {/* <i class="fa-regular fa-arrow-left"></i> */}
              </Link>
            </div>
          </div>
          
        </div>
        <hr />
        <StaffTable/>
         
      </div>
    </div>
  </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}
