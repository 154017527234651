import React from 'react'
// import "./Login.css"
import { Outlet, Link } from "react-router-dom";

export default function Registration() {
  return (
    <div><div className="background">
    <div className="container">
      <div className="uk-width-1-1">
        <form className="uk-form-stacked formcss">
          <div className="uk-width-1-1 headerddiv">
            <div className="uk-grid">
              <div className="uk-width-auto">
                <Link to="">
                  <img src="/img/logo.png" className="logo" />{" "}
                </Link>
              </div>
              <div className="uk-width-expand uk-text-right">
                <Link
                  className="uk-button uk-button-default create_btn"
                  to="/admin/login"
                >
                  Login Account
                </Link>
              </div>
            </div>
            <img src="/img/login.png" className="logingif" />
            <p className="smalltxt">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
            </p>
          </div>
          <h3 className="uk-text-center signinhd">USER SIGN UP</h3>
          <span className="signinsubhd">Create An Account</span>
          <div className="inputdiv">
            <div className="uk-alert-danger uk-hidden" uk-alert="">
              <a className="uk-alert-close" uk-close="" />
              <p>Lorem ipsum dolor sit amet, consecte.</p>
            </div>
            <div className="uk-margin uk-margin-small-top">
              <div className="uk-inline">
                <i className="uk-form-icon fa fa-user-circle-o" />
                <input
                  className="uk-input"
                  id="form-stacked-text"
                  type="text"
                  placeholder="Enter User"
                />
              </div>
            </div>
            <div className="uk-margin">
              <div className="uk-inline">
                <i className="uk-form-icon fa fa-envelope" />
                <input
                  className="uk-input"
                  id="form-stacked-text"
                  type="email"
                  placeholder="Enter Email"
                />
              </div>
            </div>
            <div className="uk-margin">
              <div className="uk-inline">
                <i className="uk-form-icon fa fa-lock" />
                <input
                  className="uk-input"
                  id="form-stacked-text"
                  type="password"
                  placeholder="Enter your password"
                />
              </div>
            </div>
            <div className="uk-margin">
              <div className="uk-inline">
                <i className="uk-form-icon fa fa-unlock-alt" />
                <input
                  className="uk-input"
                  id="form-stacked-text"
                  type="password"
                  placeholder="Enter your confirm password"
                />
              </div>
            </div>
            <div className="uk-text-center uk-width-1-1@s">
              <button className="uk-button uk-button-default gbtn uk-margin-remove-top">
                Sign up
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="uk-width-1-1 footer">
        <span className="copy">©2022 IWCN Group</span>
      </div>
    </div>
  </div>
  </div>
  )
}
