import React from 'react'

export default function NotFound() {
  return (
    <div style={{backgroundColor:'white'}}>
        <center>
        <img src={process.env.PUBLIC_URL + '/img/notfound.jpg'} style={{width:"50%"}} alt="" />
        </center>
       
        </div>
  )
}
