import React, { useEffect, useState } from 'react'
import { Outlet, Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import {
    ForgetPasswordUsersAPI,
    key,
    DeleteUsersAPI,
    MainURL
  } from "../../../API";
export default function ForgetPassword() {
    
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const inputEventEmail = (event) =>{
      
    setEmail(event.target.value)
}
const inputEventPassword = (event) =>{
    
    setPassword(event.target.value)
}
const onSubmit = async (event)=>{
  event.preventDefault()
  //console.log(email, password)
  console.clear()
  showLoging("loading... please wait")
  try {
    const response =  await axios.post(ForgetPasswordUsersAPI, {
      key:key,
      email: email,
      
      type: 1,

    });

    if (response.data.status_code === 200) {
      

     
    showToastMessage(response.data.message)

    
    setTimeout(() => {
    //   navigate("/admin");
        
      }, 1000);
    }
    else{
      // successMessage(response.data.message)

      showToastMessageError(response.data.message)

    }
}catch (error) {
  // successMessage("Sorry! Sever error")
  showToastMessageError("error")

}
}

const showToastMessage = (text) => {
  toast.success(text, {
      position: toast.POSITION.TOP_RIGHT
  });

   
};

const showLoging = (text) => {
  toast(text, {
      position: toast.POSITION.TOP_RIGHT
  });

   
};

const showToastMessageError = (text) => {
toast.error(text, {
  position: toast.POSITION.TOP_RIGHT
});
}

  return (
    <div><div className="background">
    <div className="container_login">
      <div className="uk-width-1-1">
        <form onSubmit={onSubmit}  method="POST" className="uk-form-stacked  formcss">
          <div className="uk-width-1-1 headerddiv">
            <div className="uk-grid">
              <div className="uk-width-auto">
                <Link to="">
                  <img src={MainURL+'back-end/storage/app/public/img/logo/logo.png?rand=23'} className="logo" />{" "}
                </Link>
              </div>
              <div className="uk-width-expand uk-text-right">
                {/* <Link
                  className="uk-button uk-button-default create_btn"
                  to="/admin/registration"
                >
                  Create Account
                </Link> */}
              </div>
            </div>
            {/* <img src={process.env.PUBLIC_URL + './img/bell.png'} alt="kd" /> */}

            <img src={process.env.PUBLIC_URL + '/img/login.png'} className="logingif" />
            {/* <p className="smalltxt">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
            </p> */}
          </div>
          <h3 className="uk-text-center signinhd">Forget Password</h3>
          <span className="signinsubhd">Welcome Back</span>
          <div className="inputdiv">
            <div className="uk-alert-danger uk-hidden" uk-alert="">
              <a className="uk-alert-close" uk-close="" />
              {/* <p>Lorem ipsum dolor sit amet, consecte.</p> */}
            </div>
            
            <div className="uk-margin uk-margin-small-top">
              <div className="uk-inline">
                <i className="uk-form-icon fa fa-user-circle-o" />
                <input
                  className="uk-input"
                  id="email"
                  type="email"
                  onChange={inputEventEmail}
                  value={email}
                  placeholder="Enter Email or Username"
                />
              </div>
            </div>
            
            <div className="uk-child-width-expand" uk-grid="">
              <div className="uk-text-left">
                <label className="uk-form-label" htmlFor="form-stacked-text">
                  {/* <input className="uk-checkbox" type="checkbox" /> Remember? */}
                </label>
              </div>
               
            </div>
            <div className="uk-text-center uk-width-1-1@s">
              <button type='submit' className="uk-button uk-button-default gbtn">Submit</button>
              <Link to='/admin/login' className="uk-button uk-button-default gbtn">Back</Link>
            </div>
          </div>
        </form>
      </div>
      <div className="uk-width-1-1 footer">
        <span className="copy">©2023 IWCN Group</span>
      </div>
    </div>
  </div>
  <ToastContainer />

  </div>
  )
}
