import React, { useState } from "react";
import CheckAuthoristion from "../CheckAuthoristion";

import { Outlet, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AddonUplodeCSVAPI, key, DeleteUsersAPI } from "../../../API";
import { ToastContainer, toast } from "react-toastify";
import LoadingModal from "../../loader/LoadingModal";
export default function UplodeCSV() {
  const [file, setFile] = useState("");
  const [peoccess, setProccess] = useState("");
  const [loadeing, setLoadeing] = useState(false);
  const [uploade_Type, set_uploade_Type] = useState(null);


  function hanleFile(e) {
    // ////console.log(e.target.files);
    setFile(e.target.files[0]);
    ////console.log(file);
  }
  const onSubmit = async (event) => {
    event.preventDefault();

    if (file == "") {
      showToastMessageError("select the file");
    }
    else {
      setLoadeing(true)


      const formData = new FormData();

      formData.append("file", file);
      formData.append("key", key);
      formData.append("admin_token", localStorage.getItem("admin_token"));
      formData.append("admin_id", localStorage.getItem("admin_id"));
      formData.append("uploade_type", uploade_Type);


      // ////console.log("ok fafile ");
      setProccess(" loading... please wait");

      //   showToastMessageError("res.data.message")
      setFile("")

      axios.post(AddonUplodeCSVAPI, formData).then(
        (res) => {
          if (res.data.status_code === 200) {
            // successMessage(res.data.message);
            showToastMessage(res.data.message);
            // setUserPhotoFile(res.data.data[0].img)
            // ////console.log(res.data.data[0].img)
            setProccess("");
            setLoadeing(false)
            localStorage.setItem("update_ifream", Math.random(999999))
            set_uploade_Type(null)

          } else {
            showToastMessageError(res.data.message);
            ////console.log("dfj;");
            setProccess("");
            setLoadeing(false)
            localStorage.setItem("update_ifream", Math.random(999999))
            set_uploade_Type(null)



          }
        },
        (reason) => {
          console.error(reason); // Error!
          // showToastMessage("data insert successfully");
          setProccess("");
          setLoadeing(false)
          localStorage.setItem("update_ifream", Math.random(999999))
          set_uploade_Type(null)



        },
      );

    }
  };

  const showToastMessage = (text) => {
    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastMessageError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <div className="" uk-grid="">
      <div className="uk-width-expand@m">
        {/* <div className="" uk-grid="">
          <div className="uk-width-auto">
            <h3 className="ukhd">Add-on Uplode CSV</h3>
          </div>
          <div className="uk-width-expand"></div>
        </div> */}
        <div className="uk-card uk-card-body uk-card-default addcandidate">
          <h3 className="ukshd">{/* <u> Basic Informations</u> */}</h3>
          {/* {loadeing ? <><div><span uk-spinner="ratio: 2.3"></span><h3>  Please wait... </h3></div></> : <> </>} */}
         <form method="post" onSubmit={onSubmit} encType="multipart/form-data">
            <div className="uk-grid-small" uk-grid="">
              <div className="uk-width-expand@s ">
                <h5>Import Add-ons Database
                </h5>

                <div className="uk-form-controls uk-margin-small-top">
                  <label>
                    <input
                      className="uk-radio"
                      type="radio"
                      name="Product"
                      // onClick={""}

                      onChange={(e) => { set_uploade_Type(0) }}


                    // defaultValue="Not_Comfortable"
                    />{" "}
                    Add a new opportunity to the database
                  </label>
                  <br />



                  <label>
                    <input
                      className="uk-radio"
                      type="radio"
                      name="Product"



                      onChange={(e) => { set_uploade_Type(1) }}

                    />{" "}
                    Rewrite whole table
                  </label>
                </div>
                <div className="uk-margin ">
                  <label className="uk-form-label"> Choose CSV for Import </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <input name="resume_pic" onChange={hanleFile} type="file" />
                  </div>
                </div>
              </div>
            </div>
            {/* <hr /> */}

            <div className="uk-width-1-1@s uk-margin-small-top"><br />
              {uploade_Type !== null ? <>
                <button
                  type="submit"
                  className="uk-button  uk-button-primary custombtn"
                  name="submit_candidate"
                  style={{ "background-color": "#4CAF50" }}
                >
                  <i class="fa fa-upload"></i> Upload CSV
                </button>

              </> : <></>}

              <p>{peoccess}</p>
            </div>
            <div>
              <hr /><h5>Export Add-ons Database
              </h5> <a
                className="uk-button  uk-button-primary custombtn"
                href="https://vineet.customlinkbuilders.com/back-end/public/api/admin/addontageturl/download" download target="_blank">                <i class="fa fa-download" aria-hidden="true"></i>{" "}
                Download CSV</a>
            </div>
          </form>
          <LoadingModal isLoading={loadeing} />

        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
}
