export const key = "adc7030f-27c6-4d9e-9288-da1ed14dc37f";
// export const BaseURL = "https://develop.customlinkbuilders.com/back-end/public/api";
// export const ImageURL = "https://develop.customlinkbuilders.com/back-end/storage/app/";
// export const MainURL = "https://develop.customlinkbuilders.com/";

export const BaseURL = "https://vineet.customlinkbuilders.com/back-end/public/api";
export const ImageURL = "https://vineet.customlinkbuilders.com/back-end/storage/app/";
export const MainURL = "https://vineet.customlinkbuilders.com/";

export const GetAllAdminAPI = `${BaseURL}/admin/get/all`;
export const GetAdminAPI = `${BaseURL}/admin/get`;

export const DeleteUsersAPI = `${BaseURL}/admin/delete`;
export const UpdateUsersAPI = `${BaseURL}/admin/update`;

export const UpdateUserImgsAPI = `${BaseURL}/admin/update/img`;

export const UpdatePasswordUsersAPI = `${BaseURL}/admin/update/password`;
export const ForgetPasswordUsersAPI = `${BaseURL}/admin/forget/password`;



export const AddUsersAPI = `${BaseURL}/admin/registration`;

export const GetClientsAPI = `${BaseURL}/client/get/all`;
export const GetClientAPI = `${BaseURL}/client/get`;

export const AddClientsAPI = `${BaseURL}/client/add`;
export const DeleteClientAPI = `${BaseURL}/client/delete`;
export const UpdateClientAPI = `${BaseURL}/client/update`;
export const UpdatePasswordClinentAPI = `${BaseURL}/client/update/password`;



export const GetAllProductAPI = `${BaseURL}/product/get/all`;
export const GetProductAPI = `${BaseURL}/product/get`;
export const UpdateProductAPI = `${BaseURL}/product/update`;

export const GetAllDomainAPI = `${BaseURL}/domain/get/all`;


export const AddProductAPI = `${BaseURL}/product/add`;
export const DeleteProductAPI = `${BaseURL}/product/delete`;


export const GetAllSignalsTypesAPI = `${BaseURL}/signals_types/get/all`;


export const UpdateSiteLogoAPI = `${BaseURL}/update/site/logo`;

export const UplodeCSVAPI = `${BaseURL}/admindasboard/uplode/csv`;
export const AddonUplodeCSVAPI = `${BaseURL}/targeturladdon/uplodecsv`;

export const add_customsignals_form_admin = `${BaseURL}/add/customsignals_form_admin`;
export const admin_dasboard_counting_api = `${BaseURL}/get/counting`;


export const add_all_customsignals_form_admin = `${BaseURL}/get/all/customsignals_form_admin`;
export const delete_customsignals_form_admin = `${BaseURL}/delete/customsignals_form_admin`;
export const update_customsignals_form_admin = `${BaseURL}/update/customsignals_form_admin`;

export const get_target_url = `${BaseURL}/get/target_url`;
export const get_target_url_addon = `${BaseURL}/get/target_url/addon`;

export const delete_target_url = `${BaseURL}/delete/targeturl`;

export const all_select_delete_target_url = `${BaseURL}/targeturl/delete/all`;



export const delete_target_url_addon = `${BaseURL}/delete/targeturl/addon`;



export const get_target_urlbyproduct = `${BaseURL}/get/target_url/bybroduct`;





















