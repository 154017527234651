import React, { useState } from "react";
import CheckAuthoristion from "../CheckAuthoristion";

import { Outlet, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { UplodeCSVAPI, key, DeleteUsersAPI, BaseURL, ImageURL } from "../../../API";
import { ToastContainer, toast } from "react-toastify";
import LoadingModal from "../../loader/LoadingModal";

export default function UplodeCSV() {
  const [file, setFile] = useState("");
  const [peoccess, setProccess] = useState("");
  const [loadeing, setLoadeing] = useState(false);
  const [uploade_Type, set_uploade_Type] = useState(null);



  function hanleFile(e) {
    // console.log(e.target.files);
    setFile(e.target.files[0]);
    // console.log(file);
  }

  const download_csv_function = async () => {
    // console.log("download csv")
    document.getElementById("myBtn").disabled = true;
    setLoadeing(true)

    try {
      const response = await axios.post(
        `${BaseURL}/admin/tageturl/download5`,
        {

        }
      );
      if (response.status === 200) {
        // console.log("download csv")
        // document.getElementById("myBtn").disabled = false;


      } else {
        // console.log("download csv")
        // document.getElementById('atag').click()
        // document.getElementById("myBtn").disabled = false;



      }
    } catch (error) {
      console.log(error);

      // console.log("download csv")
      // document.getElementById('atag').click()
      // document.getElementById("myBtn").disabled = false;



    }
    setLoadeing(false)

    document.getElementById('atag').click()

  }
  const [dupilcat_data, set_dupilcat_data] = useState([]);

  const get_dupilcat_target_url = async () => {

    try {
      const response = await axios.post(`${BaseURL}/get/uploadecsv/duplicate`,
        {

        }
      );
      if (response.status === 200) {
        // console.log(response.data.data)
        // console.log(Object.values(response.data.data).length)

        if (response.data.data) {
          set_dupilcat_data(Object.values(response.data.data))
          if (Object.values(response.data.data).length > 0) {
            document.getElementById("dup_button").click()

          }
        }
        setProccess("");
        setLoadeing(false)
        set_uploade_Type(null)


      } else {

        setProccess("");
        setLoadeing(false)
        set_uploade_Type(null)

      }
    } catch (error) {

      setProccess("");
      setLoadeing(false)
      set_uploade_Type(null)

    }


  }
  const onSubmit = async (event) => {
    event.preventDefault();
    if (file == "") {
      showToastMessageError("Select the file");
    } else {
      setLoadeing(true)
      const formData = new FormData();

      formData.append("file", file);
      formData.append("key", key);
      formData.append("admin_id", localStorage.getItem("admin_id"));

      formData.append("admin_token", localStorage.getItem("admin_token"));
      formData.append("uploade_type", uploade_Type);


      // console.log("ok fafile ");
      setProccess(" loading... please wait");

      //   showToastMessageError("res.data.message")
      setFile("");

      axios.post(BaseURL + "/uploade/admin/csv", formData).then(
        (res) => {
          // console.log(res.data)
          // const match = res.data.match(/\{.*\}/);

          // if (match) {
          //   const jsonData = match[0];
          //   console.log(JSON.parse(jsonData));
          // } else {
          //   console.log('No JSON data found between curly braces.');
          // }
          if (res.data.status_code === 200) {
            // successMessage(res.data.message);
            // showToastMessage(res.data.message);
            // setProccess("");
            // setLoadeing(false)
            // set_uploade_Type(null)
            get_dupilcat_target_url()

          } else {
            // showToastMessageError(res.data.message);
            // setProccess("");
            // setLoadeing(false)
            // set_uploade_Type(null)
            get_dupilcat_target_url()


          }
        },
        (reason) => {
          // console.error(reason); // Error!
          // showToastMessage("Data Successfully Add");
          // setProccess("");
          // setLoadeing(false)
          // set_uploade_Type(null)
          get_dupilcat_target_url()

        },
      );
    }
  };

  const showToastMessage = (text) => {
    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastMessageError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  // get_dupilcat_target_url()

  return (
    <div className="" uk-grid="">
      <div className="uk-width-expand@m">
        <div className="uk-card uk-card-body uk-card-default ">
          {/* {loadeing ? <><div>
            <span uk-spinner="ratio: 3.3"></span>
            <h3>  Please wait... </h3>
          </div>
          </> : <></>} */}
      <form method="post" onSubmit={onSubmit} encType="multipart/form-data">
            <div className="uk-grid-small" uk-grid="">
              <div className="uk-width-expand@s ">
                {/* <div>
                    <h5>Browse Files
                <a 
                className="uk-button  uk-button-primary custombtn"
                href="https://manoj.customlinkbuilders.com/uploads/Sample_Template_CS_Database.csv"  download target="_blank">Demo CSV</a>
                </h5>  
                </div><hr /> */}
                <h5>Import Custom Signals Database</h5>

                <div className="uk-form-controls uk-margin-small-top">
                  <label>
                    <input
                      className="uk-radio"
                      type="radio"
                      name="Product"
                      // onClick={""}

                      onChange={(e) => { set_uploade_Type(0) }}


                    // defaultValue="Not_Comfortable"
                    />{" "}
                    Add a new opportunity to the database
                  </label>
                  <br />



                  <label>
                    <input
                      className="uk-radio"
                      type="radio"
                      name="Product"



                      onChange={(e) => { set_uploade_Type(1) }}

                    />{" "}
                    Rewrite whole table
                  </label>
                </div>
                <div className="uk-margin ">
                  <label className="uk-form-label">
                    {" "}
                    Choose CSV for Import
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <input name="resume_pic" onChange={hanleFile} type="file" />
                  </div>




                </div>

              </div>
            </div>
            <hr />

            <div className="uk-width-1-1@s uk-margin-small-top">
              {uploade_Type !== null ? <>
                <button
                  type="submit"
                  className="uk-button  uk-button-primary custombtn"
                  name="submit_candidate"
                  style={{ "background-color": "#4CAF50" }}
                >
                  <i class="fa fa-upload"></i> Upload CSV
                </button>{" "}

              </> : <></>}


              {/* <a 
                className="uk-button  uk-button-primary custombtn"
                href="https://manoj.customlinkbuilders.com/uploads/Sample_Template_CS_Database.csv"  download target="_blank">Demo CSV</a>{" "} */}
              {/* <a 
                className="uk-button  uk-button-primary custombtn"
                href="https://manoj.customlinkbuilders.com/admin/ajax_seo/?customsignals_database=db"  download target="_blank">Download CSV</a> */}
              <p>{peoccess}</p>
            </div>
            <div>
              <hr />
              <h5>Export Custom Signals Database</h5>{" "}
              {/* <a

                href={`${ImageURL}/public/customsignals_target_url_db.csv`}
                download
                target="_blank"
                id="atag"
              >


              </a>{" "} */}
              <button id="myBtn" onClick={download_csv_function} className="uk-button uk-button-primary custombtn">Download CSV</button>{" "}
              <a
                className="uk-button  uk-button-primary custombtn"
                href={`${ImageURL}/public/file/customlink_database_admin - Sheet1.csv`}
                download
                target="_blank"
              >
                <i class="fa fa-download" aria-hidden="true"></i>{" "}

                Demo CSV
              </a>
            </div>
          </form>
          <>
            {/* This is a button toggling the modal */}
            <button
              className="uk-button uk-button-default uk-margin-small-right"
              type="button"
              uk-toggle="target: #modal-example"
              id="dup_button"
              style={{ display: 'none' }}
            >
              Open
            </button>

            <div id="modal-example" uk-modal="">
              <div className="uk-modal-dialog uk-modal-body">
                <h2 className="uk-modal-title">CSV Successfully insert</h2>
                <h6 className="uk-modal-title">Some Target url is dupilcat</h6>


                {dupilcat_data.map((i, count) => {
                  return <p>{count + 1} - {i}</p>;
                })}

                <p className="uk-text-right">
                  <button
                    className="uk-button uk-button-default uk-modal-close"
                    type="button"
                  >
                    Cancel
                  </button>
                  {/* <button className="uk-button uk-button-primary" type="button">
          Save
        </button> */}
                </p>
              </div>
            </div>
          </>
          <a

            href={`${ImageURL}/public/customsignals_target_url_db.csv`}
            download
            target="_blank"
            id="atag"
          >


          </a>{" "}
          <LoadingModal isLoading={loadeing} />

        </div>
      </div>

      {/* <ToastContainer /> */}
    </div>
  );
}
