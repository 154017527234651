import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function CheckAuthoristion() {
  const navigate = useNavigate();
  const checkAuthorisation = async () => {
    if (!localStorage.getItem("builder_token")) {
      navigate("/builder/login");
    }
  };
  useEffect(() => {
    checkAuthorisation();
  }, []);
  return <></>;
}
