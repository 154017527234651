import React, { useState } from 'react'
import CheckAuthoristion from '../CheckAuthoristion'
import Sidebar from '../Sidebar'
import Header from '../Header'
import { Outlet, Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import {
  AddUsersAPI,
  key,
  DeleteUsersAPI
} from "../../../API";
import { ToastContainer, toast } from "react-toastify";


export default function AddStaffs() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");
    const [address, setAddress] = useState("");
    const [file, setFile] = useState("");

    function hanleFile(e) {
      // console.log(e.target.files);
      setFile(e.target.files[0]);
      console.log(file)
    }
    const inputEventFirstName = (event) =>{
        
      setFirstName(event.target.value)
  }
  const inputEventLastName = (event) =>{
        
    setLastName(event.target.value)
}

    const inputEventEmail = (event) =>{
        
      setEmail(event.target.value)
  }
  const inputEventPassword = (event) =>{
      
      setPassword(event.target.value)
  }
  const inputEventCPassword = (event) =>{
      
    setCPassword(event.target.value)
}
const inputEventAddress = (event) =>{
      
  setAddress(event.target.value)
}
const onSubmit = async (event)=>{
  event.preventDefault()
  if(firstName.replace(" ", "") == ""){
    showToastMessageError("please enter valid name")

  }
  else{

  
  const formData = new FormData();

    formData.append("img", file);
    formData.append("key", key);
    formData.append("firstname", firstName);
    formData.append("lastname", lastName);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("password", password);
    formData.append("cpassword", cPassword);
    formData.append("type", 1);



    
  
    axios.post(AddUsersAPI, formData).then((res) => {
      if (res.data.status_code === 200) {
        // successMessage(res.data.message);
      showToastMessage(res.data.message)

      }
      else{
      showToastMessageError(res.data.message)

      }
    },(reason) => {
      console.error(reason); // Error!
      showToastMessageError("something wrong please try again")

    },);
  
}
const showToastMessage = (text) => {
  toast.success(text, {
      position: toast.POSITION.TOP_RIGHT
  });

}

   
};

const showToastMessageError = (text) => {
toast.error(text, {
  position: toast.POSITION.TOP_RIGHT
});
}
  return (
    <div><CheckAuthoristion/>
    <div className="background">
    
    
    <div className="container">
      <div className="uk-width-1-1">
         
        <div className="uk-grid-large" uk-grid="">
        {/* <Sidebar staffs="currentli listcss" /> */}

          <div className="uk-width-expand@m content_div">
             <Header staffs="uk-active"/>
             <div className="content">
  <div className="contentdiv">
    <div className="" uk-grid="">
      <div className="uk-width-expand@m">
        <div className="" uk-grid="">
          <div className="uk-width-auto">
            <h3 className="ukhd"> Add Staff</h3>
          </div>
          <div className="uk-width-expand">
            <div className="uk-width-1-1@s uk-text-right uk-margin-small-bottom">
              <Link
                to="/admin/staff/view"
                className="uk-button uk-button-danger custombtn"
              >
                <i className="fa fa-arrow-left" /> Go Back{" "}
              </Link>
            </div>
          </div>
        </div>
        <div className="uk-card uk-card-body uk-card-default addcandidate">
          <h3 className="ukshd">
            {/* <u> Basic Informations</u> */}
          </h3>
          <form method="post" onSubmit={onSubmit} autocomplete="off" encType="multipart/form-data">
            <div className="uk-grid-small" uk-grid="">
              <div className="uk-width-1-2@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {" "}
                    First Name <span className="uk-text-danger">*</span>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="fname"
                      type="text"
                      placeholder="Enter First Name"
                      value={firstName}
                      onChange={inputEventFirstName}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="uk-width-1-2@s">
                <div className="uk-margin">
                  <label className="uk-form-label"> Last Name </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="lname"
                      type="text"
                      placeholder="Enter Last Name"
                      value={lastName}
                      onChange={inputEventLastName}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="uk-width-1-4@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {" "}
                    Phone Number <span className="uk-text-danger">*</span>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="phone"
                      type="number"
                      placeholder="Enter Phone Number"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="uk-width-1-4@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {" "}
                    Date of birth <span className="uk-text-danger">*</span>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="dob"
                      type="date"
                      placeholder="Enter Date of birth"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div> */}
              <div className="uk-width-1-2@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    Email <span className="uk-text-danger">*</span>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="email"
                      type="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={inputEventEmail}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="uk-width-1-2@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    Address <span className="uk-text-danger"></span>
                  </label>
                  <div className="uk-form-controls">
                  <textarea
                      className="uk-textarea"
                      name="address"
                      placeholder="Enter Address"
                      value={address}
                      onChange={inputEventAddress}
                      rows={3}
                    />
                  </div>
                </div>
              </div>
               
              <div className="uk-width-1-2@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {" "}
                    Password <span className="uk-text-danger">*</span>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="password"
                      type="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={inputEventPassword}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="uk-width-1-2@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    Confirm Password <span className="uk-text-danger">*</span>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="confirmpassword"
                      type="password"
                      placeholder="Enter Confirm Password"
                      value={cPassword}
                      onChange={inputEventCPassword}
                      required
                    />
                  </div>
                </div>
              </div>
              
              {/* <div class="uk-width-1-4@s">
              <div class="uk-margin">
                 <label class="uk-form-label"> Designation <span class="uk-text-danger">*</span> </label>
                  <div class="uk-form-controls">
                      <input class="uk-input" name="designation" type="text" placeholder="Enter Designation" value="">
                  </div>
              </div>
      </div>*/}
              
              {/*<div class="uk-width-1-4@s">
          <div class="uk-margin">
                 <label class="uk-form-label">Status </label>
                  <div class="uk-form-controls">
                     <select class="uk-select" name="status">
                                  <option value="0" >
                                      Active
                                  </option>
                                  <option value="1" >
                                      Inactive
                                  </option>
                              </select>
                  </div>
              </div>
      </div>*/}
              
              <div className="uk-width-expand@s">
                <div className="uk-margin ">
                  <label className="uk-form-label"> Image Upload </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <input onChange={hanleFile} name="resume_pic" type="file" />
                  </div>
                </div>
              </div>
             
               
            </div>
            <hr />
             
            <div className="uk-width-1-1@s uk-margin-small-top">
            <button
                                    type="submit"
                                    className="uk-button  uk-button-primary custombtn"
                                    name="submit_candidate"
                                    style={{"background-color": "#4CAF50"}}

                                  >
                                    <i class="fa fa-save"></i> Save Staff
                                     
                                  </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <ToastContainer/>
  </div>
  )
}
