import React from 'react'
// import './Nav.css'
import { Outlet, Link, useNavigate} from "react-router-dom";

export default function Nav() {
  return (
  <div><div className="background">
  <nav className="uk-navbar  uk-hidden@l uk-box-shadow-small  uk-text-capitalize mobilebg">
    <div className="uk-navbar-left" style={{ marginLeft: 10 }}>
      <a
        className="uk-navbar-toggle"
        href="#"
        uk-toggle="target: #offcanvas-usage"
      >
        <span uk-navbar-toggle-icon="" />
      </a>
    </div>
    <div className="uk-navbar-center">
      <form className="uk-search uk-search-default">
        <span uk-search-icon="" />
        <input
          className="uk-search-input search_input"
          type="search"
          placeholder="Search in Dashboard"
        />
      </form>
    </div>
    <div className="uk-navbar-right">
      <div className="uk-inline">
        <a href="">
          <span className="uk-badge bell_badge">
            <img src="./img/bell.png" className="navimg" />
          </span>{" "}
          <span className="uk-badge badge_position">12</span>
        </a>
        <div
          uk-dropdown="mode: click;pos: top-right"
          className="dotdropw uk-dropdown uk-dropdown-bottom-right"
        >
          <ul className="uk-nav uk-dropdown-nav dotdrop">
            <li className="uk-active">
              <a href="#">Lorem ipsum</a>
            </li>
            <li>
              <a href="#">Duis quam</a>
            </li>
            <li>
              <a href="#">Vestibulum </a>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <img src="./img/smaple2.png" className="sampleimg" />
      </div>
    </div>
  </nav>
  <div
    id="offcanvas-usage"
    uk-offcanvas="bg-close: true;overlay: true"
    className="mobilecss"
  >
    <div className="uk-offcanvas-bar">
      <button
        className="uk-offcanvas-close"
        type="button"
        uk-close=""
        style={{ color: "#000" }}
        id="closenav"
      />
      <div className="">
        <ul className="uk-list menuli">
          <li className="currentli listcss overview">
            <a href="" className="">
              <i className="fa fa-th-large sidebar_iconw" /> Overview{" "}
            </a>
            <span>
              <div className="uk-inline">
                <a href="" className="dropicon">
                  {" "}
                  <i className="moreicon fa fa-ellipsis-v" />
                </a>
                <div
                  uk-dropdown="mode: click;pos: top-right"
                  className="dotdropw uk-dropdown uk-dropdown-bottom-right"
                >
                  <ul className="uk-nav uk-dropdown-nav dotdrop">
                    <li className="uk-active">
                      <a href="#">Lorem ipsum</a>
                    </li>
                    <li>
                      <a href="#">Duis quam</a>
                    </li>
                    <li>
                      <a href="#">Vestibulum </a>
                    </li>
                  </ul>
                </div>
              </div>
            </span>
          </li>
          <li className="listcss">
          <Link to="/admin/staff/view">
            <i className="fa fa-user-plus sidebar_iconw" /> Staff
          </Link>
            <span className="uk-badge">12</span>
          </li>
          <li className="listcss">
            <a href="">
              <i className="fa fa-calendar sidebar_iconw" /> Calender
            </a>
          </li>
          <li className="listcss">
            <a href="">
              <i className="fa fa-line-chart sidebar_iconw" /> Statics
            </a>
          </li>
          <li className="listcss">
            <a href="">
              <i className="fa fa-folder-open sidebar_iconw" /> Documents
            </a>
          </li>
          <hr className="customhr uk-visible@m" />
          <li className="listcss">
            <a href="">
              <i className="fa fa-user-circle sidebar_iconw" /> Profile
            </a>
          </li>
          <li className="listcss">
            <a href="">
              <i className="fa fa-cog sidebar_iconw" /> Settings
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    uk-sticky="uk-navbar-sticky"
    className="header_height uk-sticky uk-sticky-fixed menu"
    style={{}}
  >
    <nav
      className="uk-visible@m uk-background-muted uk-dark uk-box-shadow-small uk-sticky uk-navbar"
      uk-navbar=""
      style={{ height: 68 }}
    >
      <div className="uk-navbar-left">
        <a href="">
          <img src="https://vineet.customlinkbuilders.com/back-end/storage/app/public/img/logo/logo.png" className="logo" />{" "}
        </a>
      </div>
      <div className="uk-navbar-center">
        <ul className="uk-navbar-nav menuli">
          <li className="uk-active">
            <a href="">
              <i className="fa fa-home sidebar_iconw" /> Dashboard
            </a>
          </li>
          <li className="">
          <Link to="/admin/staff/view">
            <i className="fa fa-user-plus sidebar_iconw" /> Staff
          </Link>
          </li>
          <li className="">
          <Link to="/admin/builder/view">
            <i className="fa fa-user-secret sidebar_iconw" /> Builders
          </Link>
          </li>
          <li className="">
             <Link to="/admin/product">
            <i className="fa fa-server sidebar_iconw" /> Products
          </Link>
          </li>
          <li className="">
          <Link to = "/admin/client/view">
            <i className="fa fa-users sidebar_iconw" /> Clients
          </Link>
          </li>
          <li className="">
          <Link to="/admin/site/settings">
            <i className="fa fa-cog sidebar_iconw" /> Settings
          </Link>
          </li>
        </ul>
      </div>
      <div className="uk-navbar-right">
        <ul className="uk-navbar-nav uk-margin-large-left">
          <li>
            {" "}
            <a href="#">
              <img src="./img/smaple2.png" className="sampleimg" /> Shashi Bala{" "}
              <span uk-icon="icon:  triangle-down" className="uk-icon" />
            </a>
            <div
              uk-dropdown="mode: click;pos: top-left"
              className="uk-dropdown dotdropw "
            >
              <ul className="uk-nav uk-dropdown-nav dotdrop">
                <li className="uk-active">
                  <a href="">
                    <i className="fa fa-user-circle sidebar_iconw" /> Profile
                  </a>
                </li>
                <li className="">
                  <a href="">
                    <i className="fa fa-cog sidebar_iconw" /> Settings
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
</div>

  )
}
