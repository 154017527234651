import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {
  add_customsignals_form_admin,
  key,
  add_all_customsignals_form_admin,
  delete_customsignals_form_admin,
  update_customsignals_form_admin,
} from "../../../API";
export default function Addnotes() {
  const [name, setName] = useState("");
  const [allData, setAllData] = useState([]);
  const [update, setUpdate] = useState("");
  const [svalue, setValue] = useState("");
  const [uName, setUName] = useState("");
  const [showudbtn, setshowudbtn] = useState(0);
  const [showuimputbtn, setshowuimputbtn] = useState(0);
  const [showuimpaddtbtn, setshowuimpaddtbtn] = useState(0);




  const inputEventName = (event) => {
    //console.log(event.target.value);

    setName(event.target.value);
  };

  const add = async () => {
    if (name.replace(" ", "") == "") {
      showToastMessageError("please enter valid name");
    } else {
      try {
        const response = await axios.post(add_customsignals_form_admin, {
          key: key,
          name: name,
          type: "notes",

          admin_id: localStorage.getItem("admin_id"),
          admin_token: localStorage.getItem("admin_token"),
        });
        if (response.status === 200) {
          showToastMessage(response.data.message);
          fecthApiData();
          setshowudbtn(0)
          setshowuimpaddtbtn(0)
          setshowuimputbtn(0)
          localStorage.setItem("update_ifream",Math.random(999999))


        } else {
          showToastMessageError(response.data.message);
        }
      } catch (error) {
        showToastMessageError("sorry! sever error");
      }
    }
  };
  const showToastMessage = (text) => {
    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastMessageError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const inputEventUpdation = (event) => {
    //console.log(event.target.value);
    var selectedOption = event.target.options[event.target.selectedIndex];
    var innerHTML = selectedOption.innerHTML;
    setUpdate(innerHTML)
    
    setValue(event.target.value);
    setshowudbtn(1);
    if(event.target.value == "")
    {

   
    setshowudbtn(0);
    setshowuimpaddtbtn(0)
    setshowuimputbtn(0)

    
  }

  };
  const inputEventUpdationInput = (event) => {
    setUpdate(event.target.value);

    // setUpdate(event.target.key)
  };

  const fecthApiData = async () => {
    try {
      const response = await axios.post(add_all_customsignals_form_admin, {
        key: key,
        admin_token: localStorage.getItem("admin_token"),
        type: "notes",
      });
      //console.log(response);
      if (response.status === 200) {
        setAllData(response.data.data);
      }
    } catch (error) {
      // successMessage("sorry! sever error");
    }
  };
  useEffect(() => {
    fecthApiData();
  }, []);

  const deleteRow = async () => {
    if (svalue == "") {
      showToastMessageError("please select notes ");
    } else {
      let text = "Are you sure? You want to delete this?";
      if (window.confirm(text) === true) {
        try {
          const response = await axios.post(delete_customsignals_form_admin, {
            key: key,
            id: svalue,
            type: "notes",

            admin_token: localStorage.getItem("admin_token"),
          });

          if (response.status === 200) {
            fecthApiData();
            showToastMessage(response.data.message);
            setshowudbtn(0)
            setshowuimpaddtbtn(0)
          setshowuimputbtn(0)
          localStorage.setItem("update_ifream",Math.random(999999))


          }
        } catch (error) {
          //console.log(error);
        }
      }
    }
  };

  const update_value = async () => {
    if (update.replace(" ", "") == "") {
      showToastMessageError("please enter valid name");
    } else if (svalue == "") {
      showToastMessageError("please select notes ");
    } else {
      let text = "Are you sure? You want to update this?";
      if (window.confirm(text) === true) {
        try {
          const response = await axios.post(update_customsignals_form_admin, {
            key: key,
            id: svalue,
            type: "notes",
            name: update,

            admin_token: localStorage.getItem("admin_token"),
          });

          if (response.status === 200) {
            fecthApiData();
            showToastMessage(response.data.message);
            setshowudbtn(0)
            setshowuimpaddtbtn(0)
          setshowuimputbtn(0)
          localStorage.setItem("update_ifream",Math.random(999999))

          }
        } catch (error) {
          //console.log(error);
        }
      }
    }
  };

  return (
    <div className="" uk-grid="">
      <div className="uk-width-expand@m">
        {/* <div className="" uk-grid="">
          <div className="uk-width-auto">
            <h3 className="ukhd"> Notes</h3>
          </div>
          <div className="uk-width-expand"></div>
        </div> */}
        <div className="uk-card uk-card-body uk-card-default addcandidate">
          <h3 className="ukshd">{/* <u> Basic Informations</u> */}</h3>
          {/* <form method="post" encType="multipart/form-data"> */}
            <div className="uk-grid-small" uk-grid="">
              <div className="uk-width-3-3@s">
            <h5>Update Status :</h5>

              <div className="uk-margin">
                  {/* <label className="uk-form-label"> Update </label> */}
                  <div className="uk-form-controls">
                    <select
                      class="uk-select Post_Name"
                      name="post_name"
                      onChange={inputEventUpdation}
                      // multiple
                    >
                      <option   value="">
                        Please Select
                      </option>
                      {allData.map((i) => {
                        return <option value={i.id}>{i.name}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <button
                  type="button"
                  className="uk-button  uk-button-primary custombtn"
                  name="submit_candidate"
                  style={{ "background-color": "#4CAF50" }}
                  onClick={()=>{<>{setshowuimputbtn(0)}{setshowuimpaddtbtn(1)}</>}}

                >
                  <i class="fa fa-plus-square"></i>  
                </button>
                {showudbtn == 0 ? "":<>{" "}<button
                  type="button"
                  className="uk-button  uk-button-primary custombtn"
                  name="submit_candidate"
                  style={{ "background-color": "#4CAF50" }}
                  onClick={()=>{<>{setshowuimputbtn(1)}{setshowuimpaddtbtn(0)}</>}}
                  
                >
                  <i class="fa fa-pencil-square-o fa-lg"></i>  
                </button>{" "}
                <button
                  type="butteon"
                  className="uk-button  uk-button-danger custombtn"
                  name="submit_candidate"
                  onClick={deleteRow}
                  // style={{ "background-color": "#4CAF50" }}
                >
                  <i class="fa fa-trash fa-lg"></i>  
                </button></>}

                {showuimputbtn == 0 ? "":<><div className="uk-margin">
                  {/* <label className="uk-form-label"> Update </label> */}
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="fname"
                      type="text"
                      placeholder="Enter Name for updation"
                      value={update}
                      onChange={inputEventUpdationInput}
                      required
                    />
                  </div>
                </div> <button
                  type="button"
                  className="uk-button  uk-button-primary custombtn"
                  name="submit_candidate"
                  style={{ "background-color": "#4CAF50" }}
                  onClick={update_value}
                >
                  <i class="fa fa-pencil-square-o fa-lg"></i> Save
                </button>{" "}</>}

                {showuimpaddtbtn == 0 ? "":<><div className="uk-margin"><div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="fname"
                      type="text"
                      placeholder="Enter notes Name"
                      value={name}
                      onChange={inputEventName}
                      required
                    />
                  </div></div> <button
                  type="button"
                  className="uk-button  uk-button-primary custombtn"
                  name="submit_candidate"
                  style={{ "background-color": "#4CAF50" }}
                  onClick={add}
                >
                  <i class="fa fa-plus-square"></i> Add
                </button></>}
                
                 
               
              </div>

               
            </div>
          {/* </form> */}
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
}
   
