import React, { useEffect, useState } from "react";
import CheckAuthoristion from "../CheckAuthoristion";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Outlet, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  GetClientAPI,
  key,
  UpdateClientAPI,
  UpdatePasswordClinentAPI,
} from "../../../API";
import { ToastContainer, toast } from "react-toastify";

export default function Porfile() {
  const [firstName, setName] = useState("");
  const [status, setStatus] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [address, setAddress] = useState("");

  const [phone, setphone] = useState("");

  const inputEventFirstName = (event) => {
    setName(event.target.value);
  };

  const inputEventstatus = (event) => {
    setStatus(event.target.value);
  };
  const inputEventEmail = (event) => {
    setEmail(event.target.value);
  };
  const inputEventPassword = (event) => {
    setPassword(event.target.value);
  };
  const inputEventCPassword = (event) => {
    setCPassword(event.target.value);
  };
  const inputEventAddress = (event) => {
    setAddress(event.target.value);
  };

  const inputEventPhone = (event) => {
    setphone(event.target.value);
  };

  const onSubmitUpdatePassword = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append("key", key);
    formData.append("admin_token", localStorage.getItem("admin_token"));
    formData.append("id", localStorage.getItem("updatation_id_for_client"));

    formData.append("password", password);
    formData.append("cpassword", cPassword);

    axios.post(UpdatePasswordClinentAPI, formData).then(
      (res) => {
        if (res.data.status_code === 200) {
          
          showToastMessage(res.data.message);
        } else {
          showToastMessageError(res.data.message);
        }
      },
      (reason) => {
        console.error(reason); // Error!
        showToastMessageError("something wrong please try again");
      },
    );
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (firstName.replace(" ", "") == "") {
      showToastMessageError("please enter valid name");
    } else {
      const formData = new FormData();

      formData.append("key", key);
      formData.append("admin_token", localStorage.getItem("admin_token"));
      formData.append("id", localStorage.getItem("updatation_id_for_client"));

      formData.append("name", firstName);
      formData.append("status", status);
      formData.append("email", email);
      formData.append("address", address);
      formData.append("phone", phone);

      //console.log("okfa");

      axios.post(UpdateClientAPI, formData).then(
        (res) => {
          if (res.data.status_code === 200) {
            
            showToastMessage(res.data.message);
          } else {
            showToastMessageError(res.data.message);
          }
        },
        (reason) => {
          console.error(reason); // Error!
          showToastMessageError("something wrong please try again");
        },
      );
    }
  };

  const showToastMessage = (text) => {
    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastMessageError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const getAllDataAPI = async () => {
    try {
      const response = await axios.post(GetClientAPI, {
        key: key,

        id: localStorage.getItem("updatation_id_for_client"),

        admin_token: localStorage.getItem("admin_token"),
      });
      //console.log(response.data.data);
      //console.log(response.data.data[0].firstname);

      setName(response.data.data[0].name);

       
      setStatus(response.data.data[0].status);
      setEmail(response.data.data[0].email);
      setAddress(response.data.data[0].address);
      setphone(response.data.data[0].phone);
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    getAllDataAPI();
  }, []);

  return (
    <div>
      <CheckAuthoristion />
      <div className="background">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-large" uk-grid="">
              {/* <Sidebar clients="currentli listcss" /> */}

              <div className="uk-width-expand@m content_div">
              <Header clients="uk-active"/>

                <div className="content">
                  <div className="contentdiv">
                    <div className="" uk-grid="">
                      <div className="uk-width-expand@m">
                        <div className="" uk-grid="">
                          <div className="uk-width-auto">
                            <h3 className="ukhd"> Update Client</h3>
                          </div>
                          <div className="uk-width-expand">
                            <div className="uk-width-1-1@s uk-text-right uk-margin-small-bottom">
                              <Link
                                to="/admin/client/view"
                                className="uk-button uk-button-danger custombtn"
                              >
                                <i className="fa fa-arrow-left" /> Go Back{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="uk-grid-small" uk-grid="">
                          <div className="uk-width-3-5@s">
                            <div className="uk-card uk-card-body uk-card-default addcandidate">
                              <h3 className="ukshd">
                                {/* <u> Basic Informations</u> */}
                              </h3>
                              <form
                                onSubmit={onSubmit}
                                method="post"
                                encType="multipart/form-data"
                              >
                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        {" "}
                                        Name{" "}
                                        <span className="uk-text-danger">
                                          *
                                        </span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <input
                                          className="uk-input"
                                          name="fname"
                                          type="text"
                                          placeholder="Enter First Name"
                                          value={firstName}
                                          onChange={inputEventFirstName}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        {" "}
                                        Status{" "}
                                      </label>
                                      <div className="uk-form-controls">
                                        <select
                                          onChange={inputEventstatus}
                                          className="uk-select Post_Name"
                                          name="post_name"
                                        >
                                          {/* <option value="">---Select Job---</option> */}
                                          <option
                                            selected={
                                              status == 1 ? true : false
                                            }
                                            value={1}
                                          >
                                            Active{" "}
                                          </option>
                                          <option
                                            selected={
                                              status == 0 ? true : false
                                            }
                                            value={0}
                                          >
                                            In-Active{" "}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <br />

                                  <div className="uk-width-1-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        Email{" "}
                                        <span className="uk-text-danger">
                                          *
                                        </span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <input
                                          className="uk-input"
                                          name="email"
                                          type="email"
                                          placeholder="Enter Email"
                                          readOnly
                                          value={email}
                                          onChange={inputEventEmail}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        Phone{" "}
                                        <span className="uk-text-danger">
                                          *
                                        </span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <input
                                          className="uk-input"
                                          name="confirmpassword"
                                          type="tel"
                                          placeholder="Enter Confirm Password"
                                          value={phone}
                                          onChange={inputEventPhone}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                  <div className="uk-width-2-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        Address{" "}
                                        <span className="uk-text-danger"></span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <textarea
                                          className="uk-textarea"
                                          name="address"
                                          placeholder="Enter Address"
                                          value={address}
                                          onChange={inputEventAddress}
                                          rows={3}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />

                                <div className="uk-width-1-1@s uk-margin-small-top">
                                  <button
                                    type="submit"
                                    className="uk-button  uk-button-primary custombtn"
                                    name="submit_candidate"
                                    style={{ "background-color": "#4CAF50" }}
                                  >
                                    <i class="fa fa-save"></i> Save Client
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="uk-width-2-5@s">
                            <div className="uk-card uk-card-body uk-card-default addcandidate">
                              <h3 className="ukshd">
                                <u> Password Update</u>
                              </h3>
                              <form
                                method="post"
                                onSubmit={onSubmitUpdatePassword}
                                encType="multipart/form-data"
                              >
                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-2-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        {" "}
                                        Password{" "}
                                        <span className="uk-text-danger">
                                          *
                                        </span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <input
                                          className="uk-input"
                                          name="password"
                                          type="password"
                                          placeholder="Enter Password"
                                          value={password}
                                          onChange={inputEventPassword}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                  <div className="uk-width-2-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        Confirm Password{" "}
                                        <span className="uk-text-danger">
                                          *
                                        </span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <input
                                          className="uk-input"
                                          name="confirmpassword"
                                          type="password"
                                          placeholder="Enter Confirm Password"
                                          value={cPassword}
                                          onChange={inputEventCPassword}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />

                                <div className="uk-width-1-1@s uk-margin-small-top">
                                  <button
                                    type="submit"
                                    className="uk-button  uk-button-primary custombtn"
                                    name="submit_candidate"
                                    style={{ "background-color": "#4CAF50" }}
                                  >
                                    <i class="fa fa-save"></i> Update Password
                                  </button>{" "}
                                </div>
                              </form>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
