import React, { useEffect, useState } from 'react'
import { MainURL } from '../../../API';
export default function Ifream() {
  const [key, setKey] = useState(0);

  const [count, setCount] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCount((count) => count + 1);
      update_fun()
    }, 1000);
  });

  const update_fun = () => {
    if (localStorage.getItem("update_ifream") != key) {
      setKey(localStorage.getItem("update_ifream"))
    }

  };
  return (
    <div><br /> <hr /> <iframe
      style={{ "width": "100%", "height": "1200px" }} key={key}
      src={`${MainURL}api/admin/addon_form/?userid=${localStorage.getItem("admin_id")}`} ></iframe></div>
  )
}
