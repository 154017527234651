import React, { useState } from "react";
import { BaseURL, key } from "../../../API";
import { toast } from "react-toastify";
import axios from "axios";
import ResetBackup from "./ResetBackup";

export default function Backup() {
  const [loadder, setLoadder] = useState(false);

  const onSubmit = async (event) => {
    let text = "Are you sure? You want to backup?";
    if (window.confirm(text) === true) {
      setLoadder(true);
      try {
        const response = await axios.post(BaseURL + "/backup", {
          key: key,
          admin_token: localStorage.getItem("admin_token"),
        });

        if (response.data.status_code === 200) {
          showToastMessage(response.data.message);
        }
        if (response.data.status_code === 404) {
          showToastMessageError(response.data.message);
        }
        setLoadder(false);
      } catch (error) {
        showToastMessageError("sorry! sever error");
        setLoadder(false);
      }
    }
  };

  const deleteAll = async (event) => {
    let text = "Are you sure ! You want to clear all data Admin Form, Add-on Form, Products and Quarantine data will be cleared";
    if (window.confirm(text) === true) {
      try {
        const response = await axios.post(BaseURL + '/backup/deleteall', {
          key: key,
          admin_token: localStorage.getItem("admin_token"),
        });
        //console.log(response);
        if (response.status === 200) {
          showToastMessage(response.data.message);
        }
        

      } catch (error) {
        showToastMessageError("sorry! sever error");
      }
    }
  }
  const showToastMessage = (text) => {
    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastMessageError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  return (
    <> <div className="uk-card uk-card-body uk-card-default addcandidate">
    {loadder ? (
      <>
        <div>
          <span uk-spinner="ratio: 2.3"></span>
          <h3>
            {" "}
            Please wait... <br />
            Backup are processing
          </h3>
        </div>
      </>
    ) : (
      <>
       <button onClick={deleteAll} className="uk-button uk-button-danger" type="button">
       clear all data
    </button>
        {" "}
        <button
          onClick={onSubmit}
          className="uk-button uk-button-primary"
          type="button"
        >
          Create a Backup
        </button>
      </>
    )}
  </div><br />
  {loadder?<></>:<>
  <ResetBackup/>
  
  
  </>}
  
  
  </>
   
  );
}
