import React, { useState } from 'react'
import CheckAuthoristion from '../CheckAuthoristion'
import Sidebar from '../Sidebar'
import Header from '../Header'
import { Outlet, Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import {
  AddClientsAPI,
  key,
  DeleteUsersAPI
} from "../../../API";
import { ToastContainer, toast } from "react-toastify";


export default function AddStaffs() {
  const [firstName, setFirstName] = useState("");
  const [status, setStatus] = useState(1);

  const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setphone] = useState("");
    const [address, setAddress] = useState("");
    const inputEventFirstName = (event) =>{
        
      setFirstName(event.target.value)
  }
  const inputEventstatus = (event) =>{
        
    setStatus(event.target.value)
}

    const inputEventEmail = (event) =>{
        
      setEmail(event.target.value)
  }
  const inputEventPassword = (event) =>{
    
      setPassword(event.target.value)
  }
  const inputEventPhone = (event) =>{
      
    setphone(event.target.value)
}
const inputEventAddress = (event) =>{
      
  setAddress(event.target.value)
}
const onSubmit = async (event)=>{
  event.preventDefault()
  if(firstName.replace(" ", "") == ""){
    showToastMessageError("please enter valid name")

  }
  else{

  
  // //console.log(email, password)
  try {
    const response =  await axios.post(AddClientsAPI, {
      key:key,
      name:firstName,
      status:status,
      email: email,
      address:address,
      password: password,
      phone: phone,
      admin_token: localStorage.getItem("admin_token"),


    });
    // showToastMessage(response.data.message)

    if (response.data.status_code === 200) {
      //console.log(response.data.token)
      showToastMessage(response.data.message)


    // localStorage.setItem("admin_token", response.data.token);
    // localStorage.setItem("user_id", response.data.data[0].id);
    // localStorage.setItem("user_name", response.data.data[0].name + " " + response.data.data[0].last_name);
    // localStorage.setItem("user_email", response.data.data[0].email);
    // localStorage.setItem("user_img", response.data.data[0].img);

    
    // setTimeout(() => {
    //   navigate("/admin");
        
    //   }, 1000);
    }
    else{
      showToastMessageError(response.data.message)


    }
}catch (error) {
  // successMessage("Sorry! Sever error")
  showToastMessageError("error")

}
}
}
const showToastMessage = (text) => {
  toast.success(text, {
      position: toast.POSITION.TOP_RIGHT
  });

   
};

const showToastMessageError = (text) => {
toast.error(text, {
  position: toast.POSITION.TOP_RIGHT
});
}
  return (
    <div><CheckAuthoristion/>
    <div className="background">
    
    
    <div className="container">
      <div className="uk-width-1-1">
         
        <div className="uk-grid-large" uk-grid="">
          {/* <Sidebar/> */}
      {/* <Sidebar clients="currentli listcss" /> */}

          <div className="uk-width-expand@m content_div">
          <Header clients="uk-active"/>

             <div className="content">
  <div className="contentdiv">
    <div className="" uk-grid="">
      <div className="uk-width-expand@m">
        <div className="" uk-grid="">
          <div className="uk-width-auto">
            <h3 className="ukhd"> Add Client</h3>
          </div>
          <div className="uk-width-expand">
            <div className="uk-width-1-1@s uk-text-right uk-margin-small-bottom">
              <Link
                to="/admin/client/view"
                className="uk-button uk-button-danger custombtn"
              >
                <i className="fa fa-arrow-left" /> Go Back{" "}
              </Link>
            </div>
          </div>
        </div>
        <div className="uk-card uk-card-body uk-card-default addcandidate">
          <h3 className="ukshd">
            {/* <u> Basic Informations</u> */}
          </h3>
          <form method="post" onSubmit={onSubmit} encType="multipart/form-data">
            <div className="uk-grid-small" uk-grid="">
              <div className="uk-width-1-2@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {" "}
                    First Name <span className="uk-text-danger">*</span>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="fname"
                      type="text"
                      placeholder="Enter First Name"
                      value={firstName}
                      onChange={inputEventFirstName}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="uk-width-1-2@s">
                <div className="uk-margin">
                  <label className="uk-form-label"> Email  <span className="uk-text-danger">*</span></label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="email"
                      type="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={inputEventEmail}
                      required
                    />
                  </div>
                </div>
              </div>
              {/* <div className="uk-width-1-4@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {" "}
                    Phone Number <span className="uk-text-danger">*</span>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="phone"
                      type="number"
                      placeholder="Enter Phone Number"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="uk-width-1-4@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {" "}
                    Date of birth <span className="uk-text-danger">*</span>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="dob"
                      type="date"
                      placeholder="Enter Date of birth"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div> */}
              <div className="uk-width-1-2@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                Phone  <span className="uk-text-danger">*</span>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="confirmpassword"
                      type="tel"
                      placeholder="Enter Confirm Password"
                      value={phone}
                      onChange={inputEventPhone}
                      required
                    />
                  </div>
                </div>
              </div>
               <div className="uk-width-1-2@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {" "}
                    Password <span className="uk-text-danger">*</span>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="password"
                      type="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={inputEventPassword}
                      required
                    />
                  </div>
                </div>
              </div>
              
             
              <div className="uk-width-1-2@s">
  <div className="uk-margin">
    <label className="uk-form-label">
      Status <span className="uk-text-danger">*</span>
    </label>
    <div className="uk-form-controls">
      <select onChange={inputEventstatus} className="uk-select Post_Name" name="post_name">
        {/* <option value="">---Select Job---</option> */}
        <option value={1}>Active </option>
        <option value={0}>In-Active </option>
        
      </select>
    </div>
  </div>
</div>

              <div className="uk-width-1-2@s">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    Address <span className="uk-text-danger"></span>
                  </label>
                  <div className="uk-form-controls">
                  <textarea
                      className="uk-textarea"
                      name="address"
                      placeholder="Enter Address"
                      value={address}
                      onChange={inputEventAddress}
                      rows={3}
                    />
                  </div>
                </div>
              </div>
               
              
            
              
              
             
               
            </div>
            <hr />
             
            <div className="uk-width-1-1@s uk-margin-small-top">
            <button
                                    type="submit"
                                    className="uk-button  uk-button-primary custombtn"
                                    name="submit_candidate"
                                    style={{"background-color": "#4CAF50"}}

                                  >
                                    <i class="fa fa-save"></i> Save Client
                                    
                                  </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <ToastContainer/>
  </div>
  )
}
