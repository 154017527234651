import React, { useEffect, useState } from "react";
import CheckAuthoristion from "../CheckAuthoristion";
import Header from "../Header";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { GetAllSignalsTypesAPI, key, AddProductAPI, GetAllDomainAPI, add_all_customsignals_form_admin } from "../../../API";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";


export default function AddProduct() {
  const navigate = useNavigate();

  let API = GetAllSignalsTypesAPI;
  const [signals_types, set_signals_types] = useState([]);
  const [all_notes, set_all_notes] = useState([]);

  const [allDomain, setAllDomain] = useState([]);
  const [allDomainSelect, setAllDomainSelect] = useState([]);

  const [loadeing, setLoadeing] = useState(false);


  const fecthApiData = async (url) => {
    try {
      const response = await axios.post(url, {
        key: key,
        admin_token: localStorage.getItem("admin_token"),

      });
      // //console.log(response)
      if (response.status === 200) {
        set_signals_types(response.data.data);
      }
    } catch (error) {

    }


    try {
      const response = await axios.post(GetAllDomainAPI, {
        key: key,
        admin_token: localStorage.getItem("admin_token"),

      });
      // //console.log(response.data.data)

      if (response.status === 200) {

        setAllDomain(response.data.data);
        setLoadeing(true)

        var temp = [];
        response.data.data.filter((i) => {
          // //console.log(i)
          temp.push({ value: i.targeturl, label: i.targeturl });
          return 0;
        });
        setAllDomainSelect(temp)

      }
    } catch (error) {

      setLoadeing(true)

    }
  };
  useEffect(() => {
    fecthApiData(API);
  }, []);







  const [allNiche, setAllNiche] = useState([]);

  useEffect(() => {
    // Define an asynchronous function to fetch data
    const fetchData = async () => {
      try {
        const response = await axios.post(add_all_customsignals_form_admin, {
          key: key,
          admin_token: localStorage.getItem("admin_token"),
          type: "niche",
        });

        // Check if the response status is 200 (OK)
        if (response.status === 200) {
          // Set the retrieved data in the component's state
          // //console.log('get  all niche')


          //console.log(response.data.data)

          var temp = [];
          response.data.data.filter((i) => {
            // //console.log(i)
            temp.push({ value: i.id, label: i.name });
            return 0;
          });
          setAllNiche(temp)






        } else {
          // Handle unexpected status codes if necessary
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        // Handle errors gracefully, e.g., show an error message to the user
        console.error("An error occurred:", error);

      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
    
 
  }, []);



  useEffect(() => {
    // Define an asynchronous function to fetch data
    localStorage.removeItem("set_domain_values")
    localStorage.removeItem("set_domain_values_aac")
    localStorage.removeItem("set_niche_values_aac")
    localStorage.removeItem("set_sub_niche_values_aac")

  }, []);

  const [allSubNiche, setAllSubNiche] = useState([]);

  useEffect(() => {
    // Define an asynchronous function to fetch data
    const fetchData = async () => {
      try {
        const response = await axios.post(add_all_customsignals_form_admin, {
          key: key,
          admin_token: localStorage.getItem("admin_token"),
          type: "sub_niche",
        });

        // Check if the response status is 200 (OK)
        if (response.status === 200) {
          var temp = [];
          response.data.data.filter((i) => {
            // //console.log(i)
            temp.push({ value: i.id, label: i.name });
            return 0;
          });
          setAllSubNiche(temp)
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
      try {
        const response = await axios.post(add_all_customsignals_form_admin, {
          key: key,
          admin_token: localStorage.getItem("admin_token"),
          type: "notes",
        });
        //console.log(response)
        if (response.status === 200) {
          set_all_notes(response.data.data);
        }
      } catch (error) {

      }

    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []);







  const [name, setName] = useState("");
  const [anyDR, setAnyDR] = useState("");
  const [minDR, setMinDR] = useState("");
  const [maxDR, SetMaxDR] = useState("");
  const [type, setType] = useState("");
  const [indexed, setIndexed] = useState("");

  const [dofollow, setDofollow] = useState("");
  const [status, setStatus] = useState("");
  const [anyAverageTraffic, setAnyAverageTraffic] = useState("");
  const [minAverageTraffic, setMinAverageTraffic] = useState("");
  const [maxAverageTraffic, setMaxAverageTraffic] = useState("");
  const [domain, setDomain] = useState("");
  const [maxDomain, setMaxDomain] = useState("");

  const inputEventName = (event) => {
    setName(event.target.value)
  }
  const inputEventAnyDR = (event) => {
    // //console.log(event.target.checked)
    if (event.target.checked) {
      setAnyDR(1)
      document.getElementById("min_dr").readOnly = true;
      document.getElementById("max_dr").readOnly = true;
    }
    else {
      // //console.log("Dd")
      setAnyDR(0)
      document.getElementById("min_dr").readOnly = false;
      document.getElementById("max_dr").readOnly = false;
    }
  }
  const [anyRD, setAnyRD] = useState(0);

  const inputEventAnyRd = (event) => {
    // //console.log(event.target.checked)
    if (event.target.checked) {
      setAnyRD(1)
      document.getElementById("min_rd").readOnly = true;
      document.getElementById("max_rd").readOnly = true;
    }
    else {
      // //console.log("Dd")
      setAnyRD(0)
      document.getElementById("min_rd").readOnly = false;
      document.getElementById("max_rd").readOnly = false;
    }
  }

  const [minRD, SetMinRD] = useState("");

  const [maxRD, SetMaxRD] = useState("");

  const inputEventMinRD = (event) => {
    // //console.log(event.target.value)
    SetMinRD(event.target.value)
  }
  const inputEventMaxRD = (event) => {
    // //console.log(event.target.value)
    SetMaxRD(event.target.value)
  }

  const [anyNiche, setAnyNiche] = useState(0);
  const [selectedNiche, setSelectedNiche] = useState([]);

  const inputEventAnyNiche = (event) => {
    // //console.log(event.target.checked)
    if (event.target.checked) {
      setAnyNiche(1)

    }
    else {
      // //console.log("Dd")
      setAnyNiche(0)

    }
  }

  const inputEventNiche = (event) => {
    // //console.log(event)
    setSelectedNiche(event)

    var values = []
    event.filter((i) => {
      // //console.log(i)
      values.push(i.value);
      return 0;
    });
    // setDomain(values);

    localStorage.setItem("set_niche_values", values)
    // //console.log(values)
  }


  const [anySubNiche, setAnySubNiche] = useState(0);
  const [selectedSubNiche, setSelectedSubNiche] = useState([]);

  const inputEventAnySubNiche = (event) => {
    // //console.log(event.target.checked)
    if (event.target.checked) {
      setAnySubNiche(1)
    }
    else {
      setAnySubNiche(0)
    }
  }
  const inputEventSubNiche = (event) => {
    // //console.log(event)
    setSelectedSubNiche(event)

    var values = []
    event.filter((i) => {
      // //console.log(i)
      values.push(i.value);
      return 0;
    });
    // setDomain(values);

    localStorage.setItem("set_sub_niche_values", values)
    //console.log(values)
  }


  const [anyAcceptLinkInsertions, setAnyAcceptLinkInsertions] = useState(0);
  const inputEventAnyAcceptLinkInsertions = (event) => {
    //console.log(event.target.checked)
    if (event.target.checked) {
      setAnyAcceptLinkInsertions(1)
      document.getElementById("AcceptLinkInsertions").disabled = true;
    }
    else {
      setAnyAcceptLinkInsertions(0)
      document.getElementById("AcceptLinkInsertions").disabled = false;
    }
  }
  const [acceptLinkInsertions, setAcceptLinkInsertions] = useState(0);
  const inputEventAcceptLinkInsertions = (event) => {
    setAcceptLinkInsertions(event.target.value)
  }

  const [anyAcceptAuthorship, setAnyAcceptAuthorship] = useState(0);
  const inputEventAnyAcceptAuthorship = (event) => {
    //console.log(event.target.checked)
    if (event.target.checked) {
      setAnyAcceptAuthorship(1)
      document.getElementById("AcceptAuthorship").disabled = true;
    }
    else {
      setAnyAcceptAuthorship(0)
      document.getElementById("AcceptAuthorship").disabled = false;
    }
  }
  const [acceptAuthorship, setAcceptAuthorship] = useState("");
  const inputEventAcceptAuthorship = (event) => {
    setAcceptAuthorship(event.target.value)
    console.log(event.target.value)
  }



  const [anyThirdPartyCheck, setAnyThirdPartyCheck] = useState(0);
  const inputEventAnyThirdPartyCheck = (event) => {
    //console.log(event.target.checked)
    if (event.target.checked) {
      setAnyThirdPartyCheck(1)
      document.getElementById("ThirdPartyCheck").disabled = true;
    }
    else {
      setAnyThirdPartyCheck(0)
      document.getElementById("ThirdPartyCheck").disabled = false;
    }
  }
  const [ThirdPartyCheck, setThirdPartyCheck] = useState("");
  const inputEventThirdPartyCheck = (event) => {
    setThirdPartyCheck(event.target.value)
  }





  const inputEventMinDR = (event) => {
    //console.log(event.target.value)
    setMinDR(event.target.value)
  }
  const inputEventMaxDR = (event) => {
    //console.log(event.target.value)
    SetMaxDR(event.target.value)
  }
  const inputEventTypes = (event) => {


    //console.log(event.target.selectedOptions)

    var values = Array.from(event.target.selectedOptions).map(({ value }) => value);
    //console.log(values);
    setType(values)
    localStorage.setItem("type_values", values)
    //console.log(type)
  }

  const inputEventIndexed = (event) => {

    var values = Array.from(event.target.selectedOptions).map(({ value }) => value);
    localStorage.setItem("set_indexed_values", values)
    setIndexed(values)

  }

  const inputEventDofollow = (event) => {
    var values = Array.from(event.target.selectedOptions).map(({ value }) => value);
    localStorage.setItem("set_dofollow_values", values)
    setDofollow(values)
  }

  const inputEventStatus = (event) => {
    //console.log(event.target.value)
    var values = Array.from(event.target.selectedOptions).map(({ value }) => value);
    localStorage.setItem("set_status_values", values)

    setStatus(values)

  }

  const inputEventAnyAverageTraffic = (event) => {
    //console.log(event.target.checked)
    if (event.target.checked) {
      setAnyAverageTraffic(1)
      document.getElementById("min_average_traffic").readOnly = true;
      document.getElementById("max_average_traffic").readOnly = true;


    }
    else {
      //console.log("Dd")

      setAnyAverageTraffic(0)
      document.getElementById("min_average_traffic").readOnly = false;
      document.getElementById("max_average_traffic").readOnly = false;



    }

  }
  const inputEventMinAverageTraffic = (event) => {
    //console.log(event.target.value)
    setMinAverageTraffic(event.target.value)
  }
  const inputEventMaxAverageTraffic = (event) => {
    //console.log(event.target.value)
    setMaxAverageTraffic(event.target.value)
  }

  const inputEventDomin = (event) => {
    //console.log(event)
    // setDomain(event.value)
    // var values = Array.from(event.selectedOptions).map(({ value }) => value);
    var values = []
    event.filter((i) => {
      // //console.log(i)
      values.push(i.value);
      return 0;
    });
    setDomain(values);

    localStorage.setItem("set_domain_values", values)
    console.log(values)
  }

  const inputEventMaxDomain = (event) => {
    console.log(event.target.value)
    setMaxDomain(event.target.value)
  }

  const onSubmit = async (event) => {
    console.log("maxDomain")
    console.log(maxDomain)


    event.preventDefault()
    console.log("Any DR aac" + anyDR_aac)
    console.log("min DR aac" + minDR_aac)
    console.log("max DR aac" + maxDR_aac)
    console.log("Any RD aac" + anyRD_aac)
    console.log("min RD aac" + minRD_aac)
    console.log("max RD aac" + maxRD_aac)


    //console.log("Any RD" + selectedNiche.length)




    if (name.replace(" ", "") == "") {
      showToastMessageError("please enter valid name")

    }
    else if (anyNiche == "0" && selectedNiche.length == 0) {
      showToastMessageError("please select niche")
    }

    else if (anySubNiche == "0" && selectedSubNiche.length == 0) {
      showToastMessageError("please select sub niche")
    }

    else {

      if (anyDR == 1) {
        setMinDR("")
        SetMaxDR("")
      }

      if (anyRD === '1') {
        SetMinRD("")
        SetMaxRD("")
      }
      //console.log(anyAverageTraffic)
      if (anyAverageTraffic == 1) {
        setMaxAverageTraffic("")
        setMinAverageTraffic("")
      }

      console.log("maxDomain", anyRD)
      console.log(localStorage.getItem("set_domain_values"))
    




      // //console.log(email, password)
      try {
        const response = await axios.post(AddProductAPI, {
          key: key,
          name: name,

          anyDR: anyDR,
          minDR: minDR,
          maxDR: maxDR,

          type: localStorage.getItem("type_values"),
          indexed: localStorage.getItem("set_indexed_values"),
          dofollow: localStorage.getItem("set_dofollow_values"),
          status: localStorage.getItem("set_status_values"),
          anyAverageTraffic: anyAverageTraffic,
          minAverageTraffic: minAverageTraffic,
          maxAverageTraffic: maxAverageTraffic,
          domain: localStorage.getItem("set_domain_values"),
          maxDomain: maxDomain,

          anyRD: anyRD,
          minRD: minRD ,
          maxRD: maxRD,

          anyNiche: anyNiche?"1":'0',
          niche: localStorage.getItem("set_niche_values"),
          anySubNiche: anySubNiche,
          subNiche: localStorage.getItem("set_sub_niche_values"),
          anyAcceptLinkInsertions: anyAcceptLinkInsertions?"1":'0',
          acceptLinkInsertions: acceptLinkInsertions,
          anyAcceptAuthorship: anyAcceptAuthorship?"1":'0',
          acceptAuthorship: acceptAuthorship,
          anyThirdPartyCheck: anyThirdPartyCheck?"1":'0',
          ThirdPartyCheck: ThirdPartyCheck,




          anyDRAAC: anyDR_aac ? '1' : null, // aac data
          minDRAAC: anyDR_aac ? null : minDR_aac,
          maxDRAAC: anyDR_aac ? null : maxDR_aac,

          typeAAC: localStorage.getItem("set_types_values_aac"),
          indexedAAC: localStorage.getItem("set_indexed_values_aac"),
          dofollowAAC: localStorage.getItem("set_dofollow_values_aac"),
          statusAAC: localStorage.getItem("set_status_values_aac"),

          anyAverageTrafficAAC: anyAverageTraffic_aac,
          minAverageTrafficAAC: anyAverageTraffic_aac ? null : minAverageTraffic_aac,
          maxAverageTrafficAAC: anyAverageTraffic_aac ? null : maxAverageTraffic_aac,

          domainAAC: localStorage.getItem("set_domain_values_aac"),
          maxDomainAAC: maxDomain_aac,

          anyRDAAC: anyRD_aac ? '1' : null,
          minRDAAC: anyRD_aac ? null : minRD_aac,
          maxRDAAC: anyRD_aac ? null : maxRD_aac,

          anyNicheAAC: anyNiche_aac ? '1' : null,
          nicheAAC: anyNiche_aac ? null : localStorage.getItem("set_niche_values_aac"),

          anySubNicheAAC: anySubNiche_aac ? '1' : null,
          subNicheAAC: anySubNiche_aac ? null : localStorage.getItem("set_sub_niche_values_aac"),
          
          anyAcceptLinkInsertionsAAC: anyAcceptLinkInsertions_aac,
          acceptLinkInsertionsAAC: anyAcceptLinkInsertions_aac ? null : AcceptLinkInsertions_aac,

          anyAcceptAuthorshipAAC: anyAcceptAuthorship_aac,
          acceptAuthorshipAAC: anyAcceptAuthorship_aac ? null : AcceptAuthorship_aac,

          anyThirdPartyCheckAAC: anyThirdPartyCheck_aac,
          ThirdPartyCheckAAC: anyThirdPartyCheck_aac ?null :ThirdPartyCheck_aac,

          or_and_not_value: dataType,
          aacType: show_add_additional_conditional_form ? '1':'0',




          admin_id: localStorage.getItem("admin_id"),
          admin_token: localStorage.getItem("admin_token"),



        });
        // showToastMessage(response.data.message)

        if (response.data.status_code === 200) {
          // //console.log(response.data.token)
          showToastMessage(response.data.message)
          setTimeout(navigate("/admin/product"), 1000);
        
        }
        else {
          showToastMessageError(response.data.message)
        }
      } catch (error) {
        showToastMessageError("error")
      }
    }
  }











  const showToastMessage = (text) => {
    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT
    });


  };

  const showToastMessageError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_RIGHT
    });
  }




  // add additional conditional  **********************************************************************


  const [show_add_additional_conditional_form, set_show_add_additional_conditional_form] = useState(false);

  const [name_aac, setName_aac] = useState("");
  const [anyDR_aac, setAnyDR_aac] = useState(false);
  const [minDR_aac, setMinDR_aac] = useState(null);
  const [maxDR_aac, setMaxDR_aac] = useState(null);

  const [anyRD_aac, setAnyRD_aac] = useState(false);
  const [minRD_aac, setMinRD_aac] = useState(null);
  const [maxRD_aac, setMaxRD_aac] = useState(null);

  const [anyAverageTraffic_aac, setAnyAverageTraffic_aac] = useState(false);
  const [minAverageTraffic_aac, setMinAverageTraffic_aac] = useState(null);
  const [maxAverageTraffic_aac, setMaxAverageTraffic_aac] = useState(null);

  const [dataType, setDataType] = useState(null);


  // const [type, setType] = useState("");
  // const [indexed, setIndexed] = useState("");

  // const [dofollow, setDofollow] = useState("");
  // const [status, setStatus] = useState("");
  // const [anyAverageTraffic, setAnyAverageTraffic] = useState("");
  // const [minAverageTraffic, setMinAverageTraffic] = useState("");
  // const [maxAverageTraffic, setMaxAverageTraffic] = useState("");
  // const [domain, setDomain] = useState("");
  const [maxDomain_aac, setMaxDomain_aac] = useState(null);


  const inputEventIndexedAAC = (event) => {
    var values = Array.from(event.target.selectedOptions).map(({ value }) => value);
    localStorage.setItem("set_indexed_values_aac", values)
  }
  const inputEventStatusAAC = (event) => {
    var values = Array.from(event.target.selectedOptions).map(({ value }) => value);
    localStorage.setItem("set_status_values_aac", values)
  }
  const inputEventDofollowAAC = (event) => {
    var values = Array.from(event.target.selectedOptions).map(({ value }) => value);
    localStorage.setItem("set_dofollow_values_aac", values)
  }
  const inputEventTypesAAC = (event) => {
    var values = Array.from(event.target.selectedOptions).map(({ value }) => value);
    localStorage.setItem("set_types_values_aac", values)
  }

  const inputEventDominAAC = (event) => {
    var values = []
    event.filter((i) => {
      values.push(i.value);
      return 0;
    });
    localStorage.setItem("set_domain_values_aac", values)
  }
  const [anyThirdPartyCheck_aac, setAnyThirdPartyCheck_aac] = useState(false);
  const [ThirdPartyCheck_aac, setThirdPartyCheck_aac] = useState(null);

  const [anyAcceptLinkInsertions_aac, setAnyAcceptLinkInsertions_aac] = useState(false);
  const [AcceptLinkInsertions_aac, setAcceptLinkInsertions_aac] = useState(null);

  const [anyAcceptAuthorship_aac, setAnyAcceptAuthorship_aac] = useState(false);
  const [AcceptAuthorship_aac, setAcceptAuthorship_aac] = useState(null);

  const [anyNiche_aac, setAnyNiche_aac] = useState(false);
  const inputEventNicheAAC = (event) => {
    var values = []
    event.filter((i) => {
      values.push(i.value);
      return 0;
    });
    localStorage.setItem("set_niche_values_aac", values)
  }

  const [anySubNiche_aac, setSubAnyNiche_aac] = useState(false);
  const inputEventSubNicheAAC = (event) => {
    var values = []
    event.filter((i) => {
      values.push(i.value);
      return 0;
    });
    localStorage.setItem("set_sub_niche_values_aac", values)
  }


  return (
    <div>
      <CheckAuthoristion />
      <div className="background">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-large" uk-grid="">
              {/* <Sidebar product="currentli listcss" /> */}

              <div className="uk-width-expand@m content_div">
                <Header product="uk-active" />


                <div className="contentdiv">
                  <div className="" uk-grid="">
                    <div className="uk-width-expand@m">
                      <div className="" uk-grid="">
                        <div className="uk-width-auto">
                          <h3 className="ukhd"> Add Product</h3>
                        </div>
                        <div className="uk-width-expand">
                          <div className="uk-width-1-1@s uk-text-right uk-margin-small-bottom">
                            <Link
                              to="/admin/product"
                              className="uk-button uk-button-danger custombtn"
                            >
                              <i className="fa fa-arrow-left" /> Go Back{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                      {!loadeing ? <><div>
                        <center>
                          <span uk-spinner="ratio: 4.5"></span>
                          <h1>  Please wait... </h1>

                        </center>

                      </div>
                      </> : <>
                        <div className="uk-card uk-card-body uk-card-default addcandidate">
                          <form onSubmit={onSubmit} method="post" encType="multipart/form-data">
                            <div className="uk-grid-small" uk-grid="">
                              <div className="uk-width-1-2@s">
                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        {" "}
                                        Name <span className="uk-text-danger">*</span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <input
                                          className="uk-input"
                                          name="fname"
                                          type="text"
                                          placeholder="Enter Name"
                                          value={name}
                                          onChange={inputEventName}
                                          required
                                        />
                                      </div>
                                    </div>

                                  </div>
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-grid-small" uk-grid="">
                                      <div className="uk-width-1-4@s">
                                        <div className="uk-margin">
                                          <label className="uk-form-label">
                                            {" "}
                                            Any DR{" "}
                                          </label>
                                          <div className="uk-form-controls">
                                            <input
                                              className="uk-checkbox"
                                              type="checkbox"
                                              onChange={inputEventAnyDR}

                                            />
                                          </div>
                                        </div>

                                      </div>
                                      <div className="uk-width-3-4@s">
                                        <div className="uk-grid-small" uk-grid="">
                                          <div className="uk-width-1-2@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                {" "}
                                                Min-DR{" "}
                                              </label>
                                              <div className="uk-form-controls">
                                                <input
                                                  className="uk-input"
                                                  name="lname"
                                                  type="number"
                                                  id={"min_dr"}
                                                  min={0}
                                                  placeholder="Enter Min-DR"
                                                  value={minDR}
                                                  onChange={inputEventMinDR}
                                                  required
                                                />
                                              </div>
                                            </div>

                                          </div>

                                          <div className="uk-width-1-2@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                {" "}
                                                Max-DR{" "}
                                              </label>
                                              <div className="uk-form-controls">
                                                <input
                                                  className="uk-input"
                                                  name="max_dr"
                                                  id={"max_dr"}

                                                  type="number"
                                                  min={0}
                                                  placeholder="Enter Max-DR"
                                                  value={maxDR}
                                                  onChange={inputEventMaxDR}
                                                  required
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-2@s">

                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-grid-small" uk-grid="">
                                      <div className="uk-width-1-4@s">
                                        <div className="uk-margin">
                                          <label className="uk-form-label">
                                            Any RD{" "}
                                            <span className="uk-text-danger"></span>
                                          </label>
                                          <div className="uk-form-controls">
                                            <input
                                              className="uk-checkbox"
                                              type="checkbox"
                                              onChange={inputEventAnyRd}
                                            />
                                          </div>
                                        </div>

                                      </div>
                                      <div className="uk-width-3-4@s">

                                        <div className="uk-grid-small" uk-grid="">
                                          <div className="uk-width-1-2@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                {" "}
                                                Min RD{" "}
                                                <span className="uk-text-danger">*</span>
                                              </label>
                                              <div className="uk-form-controls">
                                                <input
                                                  className="uk-input"
                                                  name="password"
                                                  type="number"
                                                  placeholder="Enter Min-RD"
                                                  value={minRD}
                                                  onChange={inputEventMinRD}
                                                  id={"min_rd"}

                                                  required
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-2@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                {" "}
                                                Max RD{" "}
                                                <span className="uk-text-danger">*</span>
                                              </label>
                                              <div className="uk-form-controls">
                                                <input
                                                  className="uk-input"
                                                  id={"max_rd"}
                                                  name="max_rd"
                                                  type="number"
                                                  placeholder="Enter Max-RD"
                                                  value={maxRD}
                                                  onChange={inputEventMaxRD}
                                                  required
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>




                                      </div>


                                    </div>


                                  </div>
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-grid-small" uk-grid="">
                                      <div className="uk-width-1-4@s">
                                        <div className="uk-margin">
                                          <label className="uk-form-label">
                                            Any Average Traffic{" "}
                                            <span className="uk-text-danger"></span>
                                          </label>
                                          <div className="uk-form-controls">
                                            <input
                                              className="uk-checkbox"
                                              type="checkbox"
                                              onChange={inputEventAnyAverageTraffic}
                                            />
                                          </div>
                                        </div>

                                      </div>
                                      <div className="uk-width-3-4@s">
                                        <div className="uk-grid-small" uk-grid="">
                                          <div className="uk-width-1-2@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                {" "}
                                                Min-Average Traffic{" "}
                                                <span className="uk-text-danger">*</span>
                                              </label>
                                              <div className="uk-form-controls">
                                                <input
                                                  className="uk-input"
                                                  name="password"
                                                  type="number"
                                                  placeholder="Enter Min-Average Traffic"
                                                  value={minAverageTraffic}
                                                  onChange={inputEventMinAverageTraffic}
                                                  id={"min_average_traffic"}

                                                  required
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-2@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                {" "}
                                                Max-Average Traffic{" "}
                                                <span className="uk-text-danger">*</span>
                                              </label>
                                              <div className="uk-form-controls">
                                                <input
                                                  className="uk-input"
                                                  id={"max_average_traffic"}
                                                  name="max_average_traffic"
                                                  type="number"
                                                  placeholder="Enter Max-Average Traffic"
                                                  value={maxAverageTraffic}
                                                  onChange={inputEventMaxAverageTraffic}
                                                  required
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>



                                      </div>


                                    </div>


                                  </div>

                                </div>

                              </div>
                            </div>

                            <div className="uk-grid-small" uk-grid="">


                              <div className="uk-width-1-4@s">
                                <div className="uk-margin">
                                  <label className="uk-form-label">
                                    Types{" "}
                                    <span className="uk-text-danger">*</span>
                                  </label>
                                  <div className="uk-form-controls">
                                    <select
                                      class="uk-select Post_Name"
                                      name="post_name"
                                      onChange={inputEventTypes}
                                      multiple
                                      required

                                    >

                                      {signals_types.map((i) => {
                                        return <option value={i.id}>{i.name}</option>;
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-4@s">
                                <div className="uk-margin">
                                  <label className="uk-form-label">
                                    Indexed{" "}
                                    <span className="uk-text-danger">*</span>
                                  </label>
                                  <div className="uk-form-controls">
                                    <select
                                      class="uk-select Post_Name"
                                      name="post_name"
                                      onChange={inputEventIndexed}
                                      multiple
                                      required


                                    >
                                      {/* <option value="">---Select Indexed---</option> */}
                                      <option value="2">Yes</option>

                                      <option value="1">No </option>

                                      <option value="0">NA </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-4@s">
                                <div className="uk-margin">
                                  <label className="uk-form-label">
                                    Dofollow{" "}
                                    <span className="uk-text-danger">*</span>
                                  </label>
                                  <div className="uk-form-controls">
                                    <select
                                      class="uk-select Post_Name"
                                      name="post_name"
                                      onChange={inputEventDofollow}
                                      multiple
                                      required



                                    >
                                      {/* <option value="">---Select Dofollow---</option> */}
                                      <option value="2">Yes</option>

                                      <option value="1">No </option>

                                      <option value="0">NA </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-4@s">
                                <div className="uk-margin">
                                  <label className="uk-form-label">
                                    Status{" "}
                                    <span className="uk-text-danger">*</span>
                                  </label>
                                  <div className="uk-form-controls">
                                    <select
                                      class="uk-select Post_Name"
                                      name="post_name"
                                      onChange={inputEventStatus}
                                      multiple
                                      required


                                    >
                                      {/* <option value="">---Select Status---</option> */}
                                      {/* <option value="live">Live </option>

                                    <option value="pending">Panding </option>

                                    <option value="paid">Paid </option> */}

                                      {all_notes.map((i) => {
                                        return <option value={i.id}>{i.name}</option>;
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>


                              <div className="uk-width-1-2@s">
                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        {" "}
                                        Domain{" "}
                                        {/* <span className="uk-text-danger">*</span> */}
                                      </label>
                                      <div className="uk-form-controls" style={{ width: "100%" }}>

                                        <Select
                                          onChange={inputEventDomin}

                                          options={allDomainSelect}
                                          isMulti
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-grid-small" uk-grid="">
                                      <div className="uk-width-1-2@s">
                                        <div className="uk-grid-small" uk-grid="">
                                          <div className="uk-width-1-2@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                {" "}

                                                Max Domain{" "}
                                                {/* <span className="uk-text-danger">*</span> */}
                                              </label>
                                              <div className="uk-form-controls">
                                                <input
                                                  className="uk-input"
                                                  name="password"
                                                  type="number"
                                                  placeholder="Enter Max Domain"
                                                  value={maxDomain}
                                                  onChange={inputEventMaxDomain}
                                                // required

                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-2@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                Any Third-Party Check{" "}
                                                <span className="uk-text-danger">*</span>
                                              </label>
                                              <div className="uk-form-controls">
                                                <input
                                                  className="uk-checkbox"
                                                  type="checkbox"
                                                  onChange={inputEventAnyThirdPartyCheck}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>

                                      <div className="uk-width-1-2@s">
                                        <div className="uk-margin">
                                          <label className="uk-form-label">
                                            Third-Party Check{" "}
                                            <span className="uk-text-danger">*</span>
                                          </label>
                                          <div className="uk-form-controls">
                                            <select
                                              class="uk-select Post_Name"
                                              name="post_name"
                                              onChange={inputEventThirdPartyCheck}
                                              id="ThirdPartyCheck"

                                              required


                                            >
                                              <option value="">---Select Status---</option>
                                              <option value="2">Yes </option>

                                              <option value="1">No </option>
                                              <option value="0">NA </option>

                                            </select>
                                          </div>
                                        </div>
                                      </div>




                                    </div>

                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-2@s">
                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-grid-small" uk-grid="">
                                      <div className="uk-width-1-4@s">
                                        <div className="uk-margin">
                                          <label className="uk-form-label">
                                            Any Accept Link_Insertions
                                            <span className="uk-text-danger">*</span>
                                          </label>
                                          <div className="uk-form-controls">
                                            <input
                                              className="uk-checkbox"
                                              type="checkbox"
                                              onChange={inputEventAnyAcceptLinkInsertions}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="uk-width-3-4@s">
                                        <div className="uk-margin">
                                          <label className="uk-form-label">
                                            Accept Link Insertions{" "}
                                            <span className="uk-text-danger">*</span>
                                          </label>
                                          <div className="uk-form-controls">
                                            <select
                                              class="uk-select Post_Name"
                                              name="post_name"
                                              onChange={inputEventAcceptLinkInsertions}

                                              required

                                              id={"AcceptLinkInsertions"}


                                            >
                                              <option value="">---Select---</option>
                                              <option value="2">Yes </option>

                                              <option value="1">No </option>
                                              <option value="0">NA </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>

                                    </div>

                                  </div>
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-grid-small" uk-grid="">
                                      <div className="uk-width-1-4@s">
                                        <div className="uk-margin">
                                          <label className="uk-form-label">
                                            Any Accept Authorship{" "}
                                            <span className="uk-text-danger">*</span>
                                          </label>
                                          <div className="uk-form-controls">
                                            <input
                                              className="uk-checkbox"
                                              type="checkbox"
                                              onChange={inputEventAnyAcceptAuthorship}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="uk-width-3-4@s">
                                        <div className="uk-margin">
                                          <label className="uk-form-label">
                                            Accept Authorship{" "}
                                            <span className="uk-text-danger">*</span>
                                          </label>
                                          <div className="uk-form-controls">
                                            <select
                                              class="uk-select Post_Name"
                                              name="post_name"
                                              onChange={inputEventAcceptAuthorship}
                                              id={"AcceptAuthorship"}
                                              

                                              required


                                            >
                                              <option value="">---Select---</option>
                                              <option value="2">Yes </option>

                                                <option value="1">No </option>
                                                <option value="0">NA </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="uk-width-1-2@s">
                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-1-6@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        {" "}
                                        Any Niche{" "}
                                      </label>
                                      <div className="uk-form-controls">
                                        <input
                                          className="uk-checkbox"
                                          type="checkbox"
                                          onChange={inputEventAnyNiche}

                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="uk-width-5-6@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        {" "}
                                        Niche <span className="uk-text-danger">*</span>
                                      </label>
                                      <div className="uk-form-controls">


                                        <Select
                                          // onChange={inputEventDomin}
                                          onChange={inputEventNiche}
                                          value={selectedNiche}
                                          options={allNiche}
                                          isMulti
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                          isDisabled={anyNiche}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="uk-width-1-2@s">
                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-1-6@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        {" "}
                                        Any Sub Niche{" "}
                                      </label>
                                      <div className="uk-form-controls">
                                        <input
                                          className="uk-checkbox"
                                          type="checkbox"
                                          onChange={inputEventAnySubNiche}

                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="uk-width-5-6@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        {" "}
                                        Sub Niche <span className="uk-text-danger">*</span>
                                      </label>
                                      <div className="uk-form-controls">


                                        <Select
                                          onChange={inputEventSubNiche}
                                          value={selectedSubNiche}

                                          options={allSubNiche}
                                          isMulti
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                          isDisabled={anySubNiche}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>





                            </div>


                            <hr />
                            <div className="uk-grid-small" uk-grid="">
                              <div className="uk-width-1-2@s">
                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-width-1-1@s uk-margin-small-top">
                                    {!show_add_additional_conditional_form
                                    ?
                                    <>
                                       <button
                                        type="submit"
                                        className="uk-button  uk-button-primary custombtn"
                                        name="submit_candidate"
                                        style={{ "background-color": "#4CAF50" }}

                                      >
                                        <i class="fa fa-save"></i> Save Product

                                      </button>
                                    </>
                                    
                                    :<>
                                    <h3><b>Add Additional Conditional</b></h3>
                                    </>}
                                      
                                   


                                    </div>

                                  </div>
                                  
                                </div>
                              </div>
                              <div className="uk-width-1-2@s">


                                <div className="uk-width-1-1@s uk-text-right uk-margin-small-bottom">

                                  <button
                                    type='button'
                                    className="uk-button  uk-button-danger custombtn"
                                    name="submit_candidate"
                                    onClick={() => { show_add_additional_conditional_form ? set_show_add_additional_conditional_form(false) : set_show_add_additional_conditional_form(true) }}
                                  >
                                    {show_add_additional_conditional_form ?"Close" : "Add additional conditional"}
                                    

                                  </button>
                                </div>

                              </div>
                            </div>



                            {show_add_additional_conditional_form ? <>
                            <hr />


                              <div className="uk-grid-small" uk-grid="">
                                <div className="uk-width-1-2@s">
                                  <div className="uk-grid-small" uk-grid="">
                                    <div className="uk-width-1-2@s">
                                      {/* <div className="uk-margin">
                                        <label className="uk-form-label">
                                        {" "}
                                        Name <span className="uk-text-danger">*</span>
                                      </label>
                                        <div className="uk-form-controls">
                                          <input
                                          className="uk-input"
                                          name="fname"
                                          type="text"
                                          placeholder="Enter Name"
                                          value={name_aac}
                                          onChange={(e) => { setName_aac(e.target.value) }}
                                          required
                                        />
                                        </div>
                                      </div> */}
                                      <div className="uk-margin">
                                      <label className="uk-form-label">
                                        Select Type{" "}
                                        <span className="uk-text-danger">*</span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <select
                                          class="uk-select Post_Name"
                                          name="post_name"
                                          onChange={ (e)=>{setDataType(e.target.value)}}
                                          required
                                        >
                                          <option value="">---Select---</option>
                                          <option value="2">AND </option>
                                          <option value="1">OR </option>
                                          <option value="0">NOT </option>
                                          
                                        </select>
                                      </div>
                                    </div>

                                    </div>
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-grid-small" uk-grid="">
                                        <div className="uk-width-1-4@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">

                                              Any DR{" "}
                                            </label>
                                            <div className="uk-form-controls">
                                              <input
                                                className="uk-checkbox"
                                                type="checkbox"
                                                checked={anyDR_aac}
                                                onChange={(e) => { setAnyDR_aac(e.target.checked) }}
                                              />
                                            </div>
                                          </div>

                                        </div>
                                        <div className="uk-width-3-4@s">
                                          <div className="uk-grid-small" uk-grid="">
                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  {" "}
                                                  Min-DR{" "}
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-input"
                                                    type="number"
                                                  
                                                    
                                                    min={0}
                                                    placeholder="Enter Min-DR"
                                                    value={minDR_aac}
                                                    onChange={(e) => { setMinDR_aac(e.target.value) }}
                                                    required={anyDR_aac ? false : true}
                                                    readOnly={anyDR_aac ? true : false}

                                                  />
                                                </div>
                                              </div>

                                            </div>

                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  {" "}
                                                  Max-DR{" "}
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-input"

                                                 
                                                    
                                                    type="number"
                                                    min={0}
                                                    placeholder="Enter Max-DR"
                                                    value={maxDR_aac}
                                                    onChange={(e) => { setMaxDR_aac(e.target.value) }}
                                                    required={anyDR_aac ? false : true}
                                                    readOnly={anyDR_aac ? true : false}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="uk-width-1-2@s">

                                  <div className="uk-grid-small" uk-grid="">
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-grid-small" uk-grid="">
                                        <div className="uk-width-1-4@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              Any RD{" "}
                                              <span className="uk-text-danger"></span>
                                            </label>
                                            <div className="uk-form-controls">
                                              <input
                                                className="uk-checkbox"
                                                type="checkbox"
                                                checked={anyRD_aac}
                                                onChange={(e) => { setAnyRD_aac(e.target.checked) }}
                                              />
                                            </div>
                                          </div>

                                        </div>
                                        <div className="uk-width-3-4@s">

                                          <div className="uk-grid-small" uk-grid="">
                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  {" "}
                                                  Min RD{" "}
                                                  <span className="uk-text-danger">*</span>
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-input"
                                                    name="password"
                                                    type="number"
                                                    placeholder="Enter Min-RD"

                                                    value={minRD_aac}
                                                    onChange={(e) => { setMinRD_aac(e.target.value) }}
                                                    required={anyRD_aac ? false : true}
                                                    readOnly={anyRD_aac ? true : false}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  {" "}
                                                  Max RD{" "}
                                                  <span className="uk-text-danger">*</span>
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-input"
                                                    
                                                    name="max_rd"
                                                    type="number"
                                                    placeholder="Enter Max-RD"
                                                    value={maxRD_aac}
                                                    onChange={(e) => { setMaxRD_aac(e.target.value) }}
                                                    required={anyRD_aac ? false : true}
                                                    readOnly={anyRD_aac }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>


                                        </div>


                                      </div>


                                    </div>
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-grid-small" uk-grid="">
                                        <div className="uk-width-1-4@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              Any Average Traffic{" "}
                                              <span className="uk-text-danger"></span>
                                            </label>
                                            <div className="uk-form-controls">
                                              <input
                                                className="uk-checkbox"
                                                type="checkbox"
                                                checked={anyAverageTraffic_aac}
                                                onChange={(e) => { setAnyAverageTraffic_aac(e.target.checked) }}
                                              />
                                            </div>
                                          </div>

                                        </div>
                                        <div className="uk-width-3-4@s">
                                          <div className="uk-grid-small" uk-grid="">
                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  {" "}
                                                  Min-Average Traffic{" "}
                                                  <span className="uk-text-danger">*</span>
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-input"
                                                    name="password"
                                                    type="number"
                                                    placeholder="Enter Min-Average Traffic"
                                                    value={minAverageTraffic_aac}
                                                    onChange={(e) => { setMinAverageTraffic_aac(e.target.value) }}
                                                    required={anyAverageTraffic_aac ? false : true}
                                                    readOnly={anyAverageTraffic_aac ? true : false}

                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  {" "}
                                                  Max-Average Traffic{" "}
                                                  <span className="uk-text-danger">*</span>
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-input"
                                                   

                                                    name="max_average_traffic"
                                                    type="number"
                                                    placeholder="Enter Max-Average Traffic"
                                                    value={maxAverageTraffic_aac}
                                                    onChange={(e) => { setMaxAverageTraffic_aac(e.target.value) }}
                                                    required={anyAverageTraffic_aac ? false : true}
                                                    readOnly={anyAverageTraffic_aac ? true : false}

                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>



                                        </div>


                                      </div>


                                    </div>

                                  </div>

                                </div>
                              </div>

                              <div className="uk-grid-small" uk-grid="">


                                <div className="uk-width-1-4@s">
                                  <div className="uk-margin">
                                    <label className="uk-form-label">
                                      Types{" "}
                                      <span className="uk-text-danger">*</span>
                                    </label>
                                    <div className="uk-form-controls">
                                      <select
                                        class="uk-select Post_Name"
                                        name="post_name"
                                        onChange={inputEventTypesAAC}
                                        multiple
                                        required
                                      >
                                        {signals_types.map((i) => {
                                          return <option value={i.id}>{i.name}</option>;
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="uk-width-1-4@s">
                                  <div className="uk-margin">
                                    <label className="uk-form-label">
                                      Indexed
                                      <span className="uk-text-danger">*</span>
                                    </label>
                                    <div className="uk-form-controls">
                                      <select
                                        class="uk-select Post_Name"
                                        name="post_name"
                                        onChange={inputEventIndexedAAC}
                                        multiple
                                        required
                                      >
                                        {/* <option value="">---Select Indexed---</option> */}
                                        <option value="2">Yes</option>
                                        <option value="1">No </option>
                                        <option value="0">NA </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="uk-width-1-4@s">
                                  <div className="uk-margin">
                                    <label className="uk-form-label">
                                      Dofollow
                                      <span className="uk-text-danger">*</span>
                                    </label>
                                    <div className="uk-form-controls">
                                      <select
                                        class="uk-select Post_Name"
                                        name="post_name"
                                        onChange={inputEventDofollowAAC}
                                        multiple
                                        required
                                      >
                                        {/* <option value="">---Select Dofollow---</option> */}
                                        <option value="2">Yes</option>

                                        <option value="1">No </option>

                                        <option value="0">NA </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="uk-width-1-4@s">
                                  <div className="uk-margin">
                                    <label className="uk-form-label">
                                      Status
                                      <span className="uk-text-danger">*</span>
                                    </label>
                                    <div className="uk-form-controls">
                                      <select
                                        class="uk-select Post_Name"
                                        name="post_name"
                                        onChange={inputEventStatusAAC}
                                        multiple
                                        required


                                      >
                                        {/* <option value="">---Select Status---</option> */}
                                        {/* <option value="live">Live </option>

                                    <option value="pending">Panding </option>

                                    <option value="paid">Paid </option> */}

                                        {all_notes.map((i) => {
                                          return <option value={i.id}>{i.name}</option>;
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>


                                <div className="uk-width-1-2@s">
                                  <div className="uk-grid-small" uk-grid="">
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-margin">
                                        <label className="uk-form-label">
                                          {" "}
                                          Domain{" "}
                                          {/* <span className="uk-text-danger">*</span> */}
                                        </label>
                                        <div className="uk-form-controls" style={{ width: "100%" }}>

                                          <Select
                                            onChange={inputEventDominAAC}
                                            options={allDomainSelect}
                                            isMulti
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-grid-small" uk-grid="">
                                        <div className="uk-width-1-2@s">
                                          <div className="uk-grid-small" uk-grid="">
                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">


                                                  Max Domain{" "}
                                                  {/* <span className="uk-text-danger">*</span> */}
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-input"
                                                    name="password"
                                                    type="number"
                                                    placeholder="Enter Max Domain"
                                                    value={maxDomain_aac}
                                                    onChange={(e) => { setMaxDomain_aac(e.target.value) }}


                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  Any Third-Party Check{" "}
                                                  <span className="uk-text-danger">*</span>
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-checkbox"
                                                    type="checkbox"
                                                    checked={anyThirdPartyCheck_aac}
                                                    onChange={(e) => { setAnyThirdPartyCheck_aac(e.target.checked) }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="uk-width-1-2@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              Third-Party Check{" "}
                                              <span className="uk-text-danger">*</span>
                                            </label>
                                            <div className="uk-form-controls">
                                              <select
                                                class="uk-select Post_Name"
                                                name="post_name"
                                                onChange={(e) => { setThirdPartyCheck_aac(e.target.value) }}
                                                required={anyThirdPartyCheck_aac ? false : true}
                                                disabled={anyThirdPartyCheck_aac ? true : false}
                                              >
                                                <option value="">---Select---</option>
                                                <option value="2">Yes </option>
                                                <option value="1">No </option>
                                                <option value="0">NA </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="uk-width-1-2@s">
                                  <div className="uk-grid-small" uk-grid="">
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-grid-small" uk-grid="">
                                        <div className="uk-width-1-4@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              Any Accept Link_Insertions
                                              <span className="uk-text-danger">*</span>
                                            </label>
                                            <div className="uk-form-controls">
                                              <input
                                                className="uk-checkbox"
                                                type="checkbox"
                                                checked={anyAcceptLinkInsertions_aac}
                                                onChange={(e) => { setAnyAcceptLinkInsertions_aac(e.target.checked) }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="uk-width-3-4@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              Accept Link Insertions{" "}
                                              <span className="uk-text-danger">*</span>
                                            </label>
                                            <div className="uk-form-controls">
                                              <select
                                                class="uk-select Post_Name"
                                                name="post_name"
                                                onChange={(e) => { setAcceptLinkInsertions_aac(e.target.value) }}
                                                required={anyAcceptLinkInsertions_aac ? false : true}
                                                disabled={anyAcceptLinkInsertions_aac ? true : false}
                                              >
                                                <option value="">---Select---</option>
                                                <option value="2">Yes </option>
                                                <option value="1">No </option>
                                                <option value="0">NA </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-grid-small" uk-grid="">
                                        <div className="uk-width-1-4@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              Any Accept Authorship{" "}
                                              <span className="uk-text-danger">*</span>
                                            </label>
                                            <div className="uk-form-controls">
                                              <input
                                                className="uk-checkbox"
                                                type="checkbox"
                                                checked={anyAcceptAuthorship_aac}
                                                onChange={(e) => { setAnyAcceptAuthorship_aac(e.target.checked) }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="uk-width-3-4@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              Accept Authorship{" "}
                                              <span className="uk-text-danger">*</span>
                                            </label>
                                            <div className="uk-form-controls">
                                              <select
                                                class="uk-select Post_Name"
                                                name="post_name"
                                                onChange={(e) => { setAcceptAuthorship_aac(e.target.value) }}
                                                required={anyAcceptAuthorship_aac ? false : true}
                                                disabled={anyAcceptAuthorship_aac ? true : false}
                                              >
                                                <option value="">---Select---</option>
                                                <option value="2">Yes </option>
                                                <option value="1">No </option>
                                                <option value="0">NA </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="uk-width-1-2@s">
                                  <div className="uk-grid-small" uk-grid="">
                                    <div className="uk-width-1-6@s">
                                      <div className="uk-margin">
                                        <label className="uk-form-label">
                                          Any Niche
                                        </label>
                                        <div className="uk-form-controls">
                                          <input
                                            className="uk-checkbox"
                                            type="checkbox"
                                            checked={anyNiche_aac}
                                            onChange={(e) => { setAnyNiche_aac(e.target.checked) }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-width-5-6@s">
                                      <div className="uk-margin">
                                        <label className="uk-form-label">

                                          Niche <span className="uk-text-danger">*</span>
                                        </label>
                                        <div className="uk-form-controls">
                                          <Select
                                            onChange={inputEventNicheAAC}
                                            options={allNiche}
                                            isMulti
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isDisabled={anyNiche_aac}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="uk-width-1-2@s">
                                  <div className="uk-grid-small" uk-grid="">
                                    <div className="uk-width-1-6@s">
                                      <div className="uk-margin">
                                        <label className="uk-form-label">
                                          {" "}
                                          Any Sub Niche{" "}
                                        </label>
                                        <div className="uk-form-controls">
                                          <input
                                            className="uk-checkbox"
                                            type="checkbox"
                                            checked={anySubNiche_aac}
                                            onChange={(e) => { setSubAnyNiche_aac(e.target.checked) }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-width-5-6@s">
                                      <div className="uk-margin">
                                        <label className="uk-form-label">
                                          {" "}
                                          Sub Niche <span className="uk-text-danger">*</span>
                                        </label>
                                        <div className="uk-form-controls">


                                          <Select
                                            onChange={inputEventSubNicheAAC}

                                            options={allSubNiche}
                                            isMulti
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isDisabled={anySubNiche_aac}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>





                              </div>
                              <hr />
                              {show_add_additional_conditional_form
                                    ?
                                    <>
                                       <button
                                        type="submit"
                                        className="uk-button  uk-button-primary custombtn"
                                        name="submit_candidate"
                                        style={{ "background-color": "#4CAF50" }}

                                      >
                                        <i class="fa fa-save"></i> Save Product

                                      </button>
                                    </>
                                    
                                    :<>
                                    
                                    </>}
                                      
                                   




                            </> : <></>}

                          </form>
                        </div></>}


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
