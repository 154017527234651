import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { GetAllProductAPI, key, DeleteProductAPI } from "../../../API";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

export default function StaffTable() {
  const navigate = useNavigate();
  const [getData, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [FilteredData, setFilteredData] = useState([]);
  const [showLoadder, setLoadder] = useState(true);
  // const [FilteredData, setFilteredData] = useState([]);

  const getAllDataAPI = async () => {
    try {
      const response = await axios.post(GetAllProductAPI, {
        key: key,
        admin_token: localStorage.getItem("admin_token"),
      });
      console.clear();
      setData(response.data.data);
      setFilteredData(response.data.data);
      setLoadder(false)
    } catch (error) {

      setLoadder(false)

    }
  };

  useEffect(() => {
    getAllDataAPI();
  }, []);
  const columns = [
    {
      name: "S.No",
      selector: (row) => row.index,
      sortable: true,
      headerStyle: () => {
        return { width: "2px", textAlign: "center" };
      },
      maxWidth: "100px",
      minWidth: "30px",
    },
    {
      name: "Name",
      selector: (row) => <Link to={'/admin/product/info?product_id='+row.id}>{row.name}</Link>,
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "DR",

      selector: (row) => {
        if (row.drmin === null || row.drmin === "null") {
          row.drmin = 0;
        }
        if (row.drmax === null || row.drmax === "null") {
          row.drmax = 0;
        }
        if (row.drmax === 0 && row.drmin === 0) {
          row.drmin = "-";
          row.drmax = "";
        }
        return row.drmin + "-" + row.drmax;
      },
      sortable: true,
      maxWidth: "1px",
    },
    {
      name: "Average Traffic",
      selector: (row) => {
        if (
          row.min_average_traffic === null ||
          row.min_average_traffic === "null"
        ) {
          row.min_average_traffic = 0;
        }
        if (
          row.max_average_traffic === null ||
          row.max_average_traffic === "null"
        ) {
          row.max_average_traffic = 0;
        }
        if (row.max_average_traffic === 0 && row.min_average_traffic === 0) {
          row.min_average_traffic = "-";
          row.max_average_traffic = "";
        }
        return row.min_average_traffic + "-" + row.max_average_traffic;
      },
      sortable: true,
      minWidth: "130px",
    },

    {
      name: "Signal Type",
      selector: (row) => row.signal_type_name,
      sortable: true,
      minWidth: "120px",
      wrap: true,
    },
    {
      name: "Dofollow",
      selector: (row) => {
        var str = "";
        var temp = row.dofollow;
        try {
          if (temp.indexOf(1) > -1) str = str + "No ";
          if (temp.indexOf(2) > -1) str = str + "Yes ";
          if (temp.indexOf(0) > -1) str = str + "NA ";
          return str;
        } catch (e) {}

      },
      sortable: true,
    },
    {
      name: "Indexed",
      selector: (row) => {
        var str = "";
        var temp = row.indexed;
        try {
          if (temp.indexOf(1) > -1) str = str + "No ";
          if (temp.indexOf(2) > -1) str = str + "Yes ";
          if (temp.indexOf(0) > -1) str = str + "NA ";
          return str;
        } catch (e) {}
      },
      sortable: true,
    },
    {
      name: "Domains",
      selector: (row) => row.domain,
      sortable: true,
      wrap: true,
    },

    {
      name: "Max Domain",
      selector: (row) => row.max_domain,
      sortable: true,
      minWidth: "120px",
    },

    {
      name: "Status",
      selector: (row) => {
        
    
          var str = "";
          var temp = row.p_statues_name.split(",");
          temp.map(item => {
            str +=  item.charAt(0).toUpperCase() + item.slice(1)+'\n';
            // You can also do something with each item here
          });


        
        return str;
      },
      sortable: true,
      maxWidth: "10px",
      wrap: true,
    },

    {
      name: "Action",
      // minWidth: "140px",

      cell: (row) => (
        <div>
          <div>
            <i
              onClick={() => update(row.id)}
              className="  fa fa-pencil-square-o fa-lg"
              aria-hidden="true"
            ></i>
            {"  "}
            <i
              onClick={() => deleteRow(row.id)}
              className="fa fa-trash fa-lg"
              aria-hidden="true"
            ></i>
          </div>
          <div className=""></div>
        </div>
      ),
    },
  ];

  const showToastMessage = (text) => toast(text);
  useEffect(() => {
    const result = getData.filter((temp) => {
      // return student;
      let data = temp.name.toLowerCase().match(search.toLocaleLowerCase());

      return data;
    });
    setFilteredData(result);
  }, [search]);

  const update = async (id) => {
    localStorage.setItem("updatation_id_for_product", id);
    navigate("/admin/product/update");
  };

  const deleteRow = async (id) => {
    let text = "Are you sure? You want to delete this?";
    if (window.confirm(text) === true) {
      try {
        const response = await axios.post(DeleteProductAPI, {
          key: key,
          id: id,
          admin_token: localStorage.getItem("admin_token"),
        });

        if (response.status === 200) {
          getAllDataAPI();
          showToastMessage(response.data.message);
        }
      } catch (error) {
      }
    }
  };
  return (
    <div>
      {showLoadder ? (
        <div>
          <center>
            <span uk-spinner="ratio: 4.5"></span>
            <h1> Please wait... </h1>
          </center>
        </div>
      ) : (
        <DataTable
          columns={columns}
          data={FilteredData}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="330px"
          selectableRowsHighlight
          highlightOnHover
          subHeader
          // noDataComponent="No Data yet"
          subHeaderComponent={
            <input
              id="desktop-search"
              className="uk-align-right mx-2"
              placeholder="Search"
              type="search"
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          }
        />
      )}
      <ToastContainer />
    </div>
  );
}
