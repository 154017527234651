import React from "react";
import "./LoadingModal.css";

const LoadingModal = ({ isLoading }) => {
  if (!isLoading) return null;

  if (isLoading) {
    document.getElementById('overlay').style.display = 'block';

  } else {
    document.getElementById('overlay').style.display = 'none';

  }

  return (
    <div>
      <div className="loading-modal">
        <div className="loading-spinner"></div> 
        <div className="loading-text"> Please wait...</div>

      </div>

    </div>

  );
};

export default LoadingModal;
