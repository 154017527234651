import React from 'react'

export default function Tablecontante() {
  return (
    <div className="" uk-grid="">
    <div className="uk-width-expand@m">
      
      <div className="uk-card uk-card-body uk-card-default ">
        <h5>
        "Target URL" VALUE - domain.com Format <br />

"Type" VALUE - Add-on Title value, only 1 value can be chosen
<br />
"DR" VALUE - Numerical value
<br />
"Dofollow" VALUE - Yes / No / NA
<br />
"Submission Page" VALUE - URL Format
        </h5>
         
      </div>
    </div>
    {/* <ToastContainer /> */}
  </div>
  )
}
