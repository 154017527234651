import React, { useEffect, useState } from 'react'
import { MainURL } from '../../../API';

export default function Ifream() {
  const [key, setKey] = useState(0);
   
    const [count, setCount] = useState(0);

    useEffect(() => {
      setTimeout(() => {
        setCount((count) => count + 1);
    // console.clear()

        update_fun()
      }, 1000);
    });
// //console.log(count)
const update_fun = () => {
    //  //console.log(localStorage.getItem("update_ifream"))
     if(localStorage.getItem("update_ifream") != key){
        setKey(localStorage.getItem("update_ifream"))
     }
   
  };
  return (
    <div><br /> <iframe   
    style={{ "width": "100%", "height":"1150px" }} key={key}
    src={`${MainURL}api/admin/admin_form/?userid=${localStorage.getItem("admin_id")}`} ></iframe></div>
  )
}
