import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { GetClientsAPI, key, BaseURL } from "../../../API";
import { ToastContainer, toast } from "react-toastify";

import { Outlet, Link, useNavigate } from "react-router-dom";

export default function StaffTable() {
  const navigate = useNavigate();

  const [getData, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [FilteredData, setFilteredData] = useState([]);
  const [showLoadder, setShowLoadder] = useState(true);


  const getAllDataAPI = async () => {
    try {
      const response = await axios.post(BaseURL + `/get/all/quarantine`, {
        key: key,
        admin_token: localStorage.getItem("admin_token"),
      });
      console.log(response.data);

      // console.clear()

      setData(response.data.data);
      //setSearch(response.data.data);
      setFilteredData(response.data.data);
      setShowLoadder(false)

    } catch (error) {
      console.log(error);
      setShowLoadder(false)

    }
  };

  useEffect(() => {
    getAllDataAPI();
  }, []);
  const columns = [
    {
      name: "S.No",
      selector: (row) => row.index,
      // cell: (row, index) => {return index + 1},
      // cell: (index) => { return<span>{index + 1}</span>},

      sortable: true,
      style: {
        // background: "orange",
      },
      headerStyle: (selector, id) => {
        return { width: "2px", textAlign: "center" };
      },
      maxWidth: "100px",
      minWidth: "30px"
    },
    {
      name: "Target URL",
      // selector: (row) => row.targeturl,row.page
      // selector: (row) => <a href={row.page} target="_blank">{row.targeturl}</a>,
      selector: (row) => <a href={"https://www."+row.targeturl} target="_blank">{row.targeturl}</a>,

      sortable: true,
    },
    {
      name: "Respone Status",
      selector: (row) => '404 not found',
      sortable: true,
    },

    {
      name: "Check Date",
      selector: (row) => row.timestamp,
      sortable: true,
    },


    {
      name: "Action",
      cell: (row) => (
        <div className=" ">
          <div
            className=""
          // onClick={() => updateAdmin(row.id)}
          >

            <button
              onClick={() => deleteRow(row.id)}
              class="uk-button uk-button-default uk-button-small"
            >
              Move to the main Table

            </button>

            <button
              onClick={() => deleteQuarntine(row.id)}
              class="uk-button uk-button-default uk-button-small"
            >
              Remove from the system

            </button>

          </div>
        </div>
      ),
    },
  ];

  const showToastMessage = (text) => toast(text);
  useEffect(() => {
    const result = getData.filter((temp) => {
      // return student;
      let data =
        temp.targeturl.toLowerCase().match(search.toLocaleLowerCase())

      return data;
    });
    setFilteredData(result);
  }, [search]);

  const update = async (id) => {
    localStorage.setItem("updatation_id_for_client", id);
    navigate("/admin/client/update");
  };

  const deleteRow = async (id) => {
    let text = "Are you sure? You want to change this?";
    if (window.confirm(text) === true) {
      try {
        const response = await axios.post(BaseURL + `/update/quarantine`, {
          key: key,
          id: id,
          admin_token: localStorage.getItem("admin_token"),
        });

        if (response.status === 200) {
          getAllDataAPI();
          showToastMessage(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const moveToTheMainTable = async () => {
    let text = "Are you sure? You want to change this?";
    if (window.confirm(text) === true) {
      try {
        const response = await axios.post(BaseURL + `/update/all/quarantines`, {
          key: key,
          id: selectedRows,
          admin_token: localStorage.getItem("admin_token"),
        });

        if (response.status === 200) {
          setSelectedRows([])

          getAllDataAPI();

          showToastMessage(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteQuarntine = async (id) => {
    let text = "Are you sure? You want to delete?";
    if (window.confirm(text) === true) {
      try {
        const response = await axios.post(BaseURL + `/delete/quarantine`, {
          key: key,
          id: id,
          admin_token: localStorage.getItem("admin_token"),
        });

        if (response.status === 200) {
          getAllDataAPI();
          showToastMessage(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const deleteAllQuarntine = async () => {
    let text = "Are you sure? You want to select quarantine delete?";
    if (window.confirm(text) === true) {
      try {
        const response = await axios.post(BaseURL + `/delete/all/quarantine`, {
          key: key,
          id: selectedRows,
          admin_token: localStorage.getItem("admin_token"),
        });
        console.log(response)

        if (response.status === 200) {
          setSelectedRows([])

          getAllDataAPI();
          showToastMessage(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const [selectedRows, setSelectedRows] = useState([]);


  const handleChangedeleterow = async (state) => {
    // //console.clear()
    console.log(state.selectedRows)
    console.log(JSON.stringify(state.selectedRows))
    setSelectedRows(state.selectedRows)
  }
  return (
    <div>
      {showLoadder ? (
        <div>
          <center>
            <span uk-spinner="ratio: 4.5"></span>
            <h1>  Please wait... </h1>

          </center>

        </div>


      ) :

        <>
          <DataTable
            //  title = "Staff"
            columns={columns}
            data={FilteredData}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="430px"
            selectableRowsHighlight
            highlightOnHover
            subHeader
            selectableRows
            onSelectedRowsChange={handleChangedeleterow}
            subHeaderComponent={
              <input
                id="desktop-search"
                className="uk-align-right mx-2"
                placeholder="Search"
                type="search"
                name="search"
                value={search}
                noDataComponent="Your Text Here" //or your component
                onChange={(e) => setSearch(e.target.value)}
                selectableRowsSingle={true}// Enable row selection
                selectableRowsHighlight // Highlight selected rows

              />
            }
          //  subHeaderAlign="left"
          />
          <br />
          {selectedRows.length > 0 ? <div>
            <button
              className="uk-button uk-button-primary custombtn"
              id="update_btn"
              onClick={moveToTheMainTable}

            >
              <i className="fa fa-plus-square" />
              {" "}Move to the main table
              {/* <i class="fa-regular fa-arrow-left"></i> */}
            </button>{" "}

            <button
              className="uk-button uk-button-danger custombtn"
              id="update_btn"
              onClick={deleteAllQuarntine}
            >
              <i class="fa fa-trash fa-lg"></i>
              {" "} Remove from the system
              {/* <i class="fa-regular fa-arrow-left"></i> */}
            </button>
          </div> : <></>}



        </>
      }

      <ToastContainer />
    </div>
  );
}
